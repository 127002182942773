import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ErrorModal = ({ isShow, onCancel, onOk, title, description, centered = true }) => {
  return (
    <>
      <Modal show={isShow} onHide={onCancel} centered={centered}>
        <Modal.Header closeButton>
          <Modal.Title className='danger'>{title ?? ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{description}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onOk}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ErrorModal;