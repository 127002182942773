import { COLORS } from 'constant/themes'
import React from 'react'
import { Button, Modal, ModalBody } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const LogoutConfirmationModal = ({ isShow, onCancel, onOk }) => {
  const { t } = useTranslation();

  return (
    <StyledModal show={isShow}  centered={true} >
      <ModalBody>
        {t("Are you sure you want to logout")}?
      </ModalBody>
      <Modal.Footer>
        <Button 
          variant="light" 
          // style={{ background: "#464A1F", border: "none", color: "#fff" }} 
          onClick={() => onOk()}
        >
          {t("Yes")}
        </Button>
        <Button variant="dark" onClick={onCancel}>
          {t("No")}
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}

export default LogoutConfirmationModal

const StyledModal = styled(Modal)`
  .modal-header {
    background-color: ${COLORS.secondary};
  }

  .modal-content {
    background-color: ${COLORS.secondary};
  }
`