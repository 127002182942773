import { ICONS } from 'assets/images';
import MapContainer from 'components/Layout/Maps';
import Button from 'components/UI/Button';
import ErrorModal from 'components/UI/ErrorModal';
import InputField from 'components/UI/Forms/InputField';
import Select from 'components/UI/Forms/Select';
import Loading from 'components/UI/Loading';
import SuccessModal from 'components/UI/SuccessModal';
import { COLORS } from 'constant/themes';
import { Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addRegisterEquipments, getRegisterEquipments } from 'store/equipments/actions';
import { setModalClose } from 'store/equipments/reducer';
import * as Yup from "yup"

const statesInMalaysia = [
  { name: "Johor", abbreviation: "JHR" },
  { name: "Kedah", abbreviation: "KDH" },
  { name: "Kelantan", abbreviation: "KTN" },
  { name: "Kuala Lumpur", abbreviation: "KUL" },
  { name: "Labuan", abbreviation: "LBN" },
  { name: "Malacca", abbreviation: "MLK" },
  { name: "Negeri Sembilan", abbreviation: "NSN" },
  { name: "Pahang", abbreviation: "PHG" },
  { name: "Penang", abbreviation: "PNG" },
  { name: "Perak", abbreviation: "PRK" },
  { name: "Perlis", abbreviation: "PLS" },
  { name: "Putrajaya", abbreviation: "PJY" },
  { name: "Sabah", abbreviation: "SBH" },
  { name: "Sarawak", abbreviation: "SWK" },
  { name: "Selangor", abbreviation: "SGR" },
  { name: "Terengganu", abbreviation: "TRG" }
].map(state => {
  return {
    ...state,
    label: state.name,
  }
});

const RegisterEquipmentPage = () => {
  const { t } = useTranslation();

  const { equipments } = useSelector(
    (state) => state.equipments
  );

  const [validationSchema] = useState(
    Yup.object().shape({
      name: Yup.string().required(`${t("Please Input Name")}`),
      shortName: Yup.string().required(`${t("Please Input Short Name")}`),
      coordinates: Yup.string().required(`${t("Please Input Coordinates")}`),
      // province: Yup.string().required(`Please Input State`),
      city: Yup.string().required(`${t("Please Select State")}`),
      mapLink: Yup.string().required(`${t("Please Input Destination Link (Cloud Link)")}`),
    })
  );


  const coordinates = equipments.coordinates;
  const dispatch = useDispatch();

  const getEquipments = () => {
    dispatch(getRegisterEquipments(`?additionalInfo=1`));
  }

  useEffect(() => {
    getEquipments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHandleAddEquipment = async (values, props) => {
    const payload = {
      name: values.name,
      shortName: values.shortName,
      coordinates: values.coordinates,
      province: "",
      city: values.city,
      mapLink: values.mapLink,
    }
    await dispatch(addRegisterEquipments(payload)).unwrap().then(res => props.resetForm());
  }

  return (
    <Fragment>
      <Row className="g-5">
        <SuccessModal 
          isShow={equipments?.isSuccessAddEquipment} 
          title={`${t("Success")}!`}
          description={t("Successfully Added Equipment")}
          onOk={() => {
            dispatch(setModalClose())
          }}
          onCancel={() => dispatch(setModalClose())}
        />
        <ErrorModal 
          isShow={equipments?.isErrorAddEquipment} 
          title="Error!" 
          description={equipments.message} 
          onOk={() => {
            dispatch(setModalClose())
          }}
        />
        <Col xxl="6" xl="12">
          <Row className="position-relative px-4 py-4" style={{ background: "#DAD7C8", borderRadius: "20px", height: 720 }}>
            <Loading isLoading={equipments?.isLoading} text="Loading..."/>
            <h3
              style={{ fontSize: 25 }}
            >
              {t("Register Equipment")}
            </h3>
            <hr/>
            <Formik
              validationSchema={validationSchema}
              onSubmit={onHandleAddEquipment}
              initialValues={{
                name: "",
                shortName: "",
                coordinates: "",
                // province: "",
                city: "",
                mapLink: "",
                // equipmentName: "Nama Alat",
                // equipmentShortName: "Nama Pendek Alat",
                // coordinates: "Koordinat",
                // state: "Negeri",
                // selangor: "Selangor",
                // mapLink: "Link Destinasi (Cloud Link)",
              }}
            >
              {props => {
                return (
                  <Form className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <Stack gap={3}>
                      <div className="border-dark border-bottom">
                        <InputField 
                          controlId="formName"
                          type="text"
                          required={true}
                          placeholder={t("Equipment Name")}
                          value={props.values.name}
                          name="name"
                          className="border-bottom-0"
                          style={{ fontSize: 25 }}
                          {...props} 
                        />
                      </div>
                      <div className="border-dark border-bottom">
                        <InputField 
                          controlId="formShortName"
                          type="text"
                          required={true}
                          placeholder={t("Equipment Short Name")}
                          value={props.values.shortName}
                          name="shortName"
                          className="border-bottom-0"
                          style={{ fontSize: 25 }}
                          {...props} 
                        />
                      </div>
                      <div className="border-dark border-bottom">
                        <InputField 
                          controlId="formCoordinates"
                          type="text"
                          required={true}
                          placeholder={t("Coordinates")}
                          value={props.values.coordinates}
                          name="coordinates"
                          className="border-bottom-0"
                          style={{ fontSize: 25 }}
                          {...props} 
                        />
                      </div>
                      <Row className="d-flex border-dark border-bottom">
                        {/* <Col lg="6">
                          <InputField 
                            controlId="formState"
                            type="text"
                            required={true}
                            placeholder={t("State")}
                            value={props.values.state}
                            name="province"
                            className="border-bottom-0"
                            style={{ fontSize: 25 }}
                            {...props} 
                          />
                        </Col> */}
                        <Col lg="12">
                          <Select 
                            controlId="formSelangor"
                            type="text"
                            required={true}
                            placeholder={t("Selangor")}
                            value={props.values.selangor}
                            name="city"
                            className="border-bottom-0"
                            options={statesInMalaysia} 
                            style={{ fontSize: 25 }}
                            {...props} 
                          />
                        </Col>
                      </Row>
                      <div className="border-dark border-bottom">
                        <InputField 
                          controlId="formMapLink"
                          type="text"
                          required={true}
                          placeholder={t("Destination Link (Cloud Link)")}
                          value={props.values.dsetinationLink}
                          name="mapLink"
                          className="border-bottom-0"
                          style={{ fontSize: 25 }}
                          {...props} 
                        />
                      </div>
                      <Button 
                        className="w-50" 
                        variant={COLORS.dark}
                        iconLeft={t("Add Equipment")}
                        iconRight={<Image src={ICONS.plusCircleWhite}/>}
                        type="submit"
                      />
                    </Stack>
                  </Form>
                )
              }}
            </Formik>
          </Row>
        </Col>
        <Col xxl="6" xl="12" >
          <Row className="position-relative" style={{ borderRadius: "20px", height: 720 }}>
            <Loading isLoading={equipments.isLoading} text="Loading Map..."/>
            <MapContainer coordinates={coordinates} />
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
}

export default RegisterEquipmentPage;