import { IMAGES } from 'assets/images'
import React from 'react'
import { Image, Stack } from 'react-bootstrap'
import { useSelector } from 'store';

const MessageHeader = () => {

  const { chatThread, message  } = useSelector(
    (state) => state.chats
  );
  const findUser = message.list.find(message => message.sender?.id === chatThread.selectedChatThread?.user?.id)
  return (
    <Stack direction="horizontal" gap={3}>
      <div style={{ width: 50, height: 50 }}>
        <Image 
          src={findUser?.sender?.profilePicture || IMAGES.genericAvatar} 
          alt="genericAvatar" 
          style={{ borderRadius: 100 }}
          className="object-fit-cover w-100 h-100"
        />
      </div>
      <span>{findUser?.sender?.fullname}</span>
    </Stack>
  )
}

export default MessageHeader