import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useSelector } from 'store';

const useSocket = () => {
  const [socket, setSocket] = useState(null);
  const { auth } = useSelector(
    (state) => state
  );

	useEffect(() => {
		if (auth.userInfo) {
			const socket = io(process.env.REACT_APP_WEB_SOCKET, {
				extraHeaders: {
          "ngrok-skip-browser-warning": "69420"
        },
			});

			setSocket(socket);

			return () => socket.close();
		} else {
			if (socket) {
				socket.close();
				setSocket(null);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.userInfo]);

  return { socket, userInfo: auth.userInfo }
};

export default useSocket