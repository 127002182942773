import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  // eslint-disable-next-line
  TypedUseSelectorHook,
} from "react-redux";

import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer"; // Import your root reducer
import { thunk } from "redux-thunk";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      thunk,
    })
  // Add middleware or other configurations as needed
});

// ----------------------------
// Type for useSelector state autocompletion without TypeScript
/**@type TypedUseSelectorHook<ReturnType<typeof store.getState>> */
export const useSelector = useReduxSelector;

/**@type typeof store.dispatch */
export const useDispatch = () => useReduxDispatch();
