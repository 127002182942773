import { createSlice } from "@reduxjs/toolkit";
import {
  getNewsByAdmin,
  publishNewsByAdmin,
} from "./actions";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  success: null,
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    clearNewsState: (state, action) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsByAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNewsByAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        console.log(action.payload)
        state.data = action.payload.d
      })
      .addCase(getNewsByAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.m || "An error occurred";
      })

      .addCase(publishNewsByAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(publishNewsByAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        console.log(action.payload)
        state.data = action.payload.d
      })
      .addCase(publishNewsByAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.m || "An error occurred";
      })
  },
});


export const { 
  clearNewsState,
} = newsSlice.actions;

export default newsSlice.reducer;
