import React from 'react';
import { GoogleMap, Marker, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { ICONS } from 'assets/images';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Image } from 'react-bootstrap';

const transformCoordinatesToMapMarker = (coordinate, index) => {
  return (
    <Marker
      key={index}
      icon={coordinate.customIcon}
      position={{ lat: coordinate.latitude, lng: coordinate.longitude }}
    />
  );
}

const MapContainer = ({ coordinates, isCustomMarker = false, onHandleClickMarker }) => {
  const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

  const selector = useSelector(
    (state) => state.equipments
  );

  // get coordinates in redux if redux is availabe else use the default coordinates passed
  if (selector && selector.equipments && selector.equipments.coordinates) {
    coordinates = selector.equipments.coordinates;
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_KEY,
  })
  
  let coordinatesCenter = coordinates.find(coordinate => coordinate?.latitude && coordinate?.longitude);

  return (
    isLoaded &&
    <GoogleMap
      mapContainerStyle={{ width: '100%', height: '100%' }}
      center={ 
        selector.equipments.selectedCoordinates 
        ? selector.equipments.selectedCoordinates 
        : { lat: coordinatesCenter?.latitude || 0 , lng: coordinatesCenter?.longitude || 0 }
      }
      zoom={7}
      mapTypeId='satellite'
    >
      {coordinates.map((coordinate, index) => {
        return (
          isCustomMarker
          ? <React.Fragment key={index}>
              <OverlayView
                position={{ lat: coordinate.latitude, lng: coordinate.longitude }}
                mapPaneName={"overlayMouseTarget"}
              >
                <StyledMarkerContainer>
                  <div onClick={() => {
                    if (onHandleClickMarker) {
                      onHandleClickMarker(coordinate);
                    } else {
                      return null;
                    }
                  }}>
                    <Image src={ICONS.cameraMarkerIcon}/>
                  </div>
                  <span>{coordinate.shortName || ""}</span>
                </StyledMarkerContainer>
              </OverlayView>
            </React.Fragment>
        : transformCoordinatesToMapMarker(coordinate, index)
        )
      })}
    </GoogleMap>
  );
};

export default MapContainer;

const StyledMarkerContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    border-radius: 1px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62.4px;
    height: 22.4px;
    background-color: #B88500;
    color: #fff;
    font-size: 15px;
  }
`