// import { ICONS } from 'assets/images';
import Loading from 'components/UI/Loading';
import { COLORS } from 'constant/themes';
// import { WebsocketContext } from 'context/WebsocketContext';
import { Form, Formik } from 'formik';
import useSocket from 'hooks/useSocket';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { getChatMessagesByChatThreadId, getChatThreadsByAdmin, sendChatMessage } from 'store/chats/actions';
import { receievedMessages, selectChatThread, sendMessages } from 'store/chats/reducer';
import styled from 'styled-components';
import Message from './components/Message';
import MessageFooter from './components/MessageFooter';
import MessageHeader from './components/MessageHeader';
import Button from 'components/UI/Button';
import { useTranslation } from 'react-i18next';

const LiveChatPage = () => {
  const { t } = useTranslation();
  const messagesEndRef = useRef(null);

  const { chats: { chatThread, message } } = useSelector(
    (state) => state
  );
  const [isSelectedAttachment, setIsSelectedAttachment] = useState(false);
  const dispatch = useDispatch();

  const getChatThreads = () => {
    dispatch(getChatThreadsByAdmin());
  }

  useEffect(() => {
    getChatThreads();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (chatThread.isSuccess) {
      dispatch(selectChatThread(chatThread.list[0]));
      dispatch(getChatMessagesByChatThreadId({ chatThreadId: chatThread.list[0]?.id }));
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatThread.isSuccess, dispatch])
  

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    };
  };

  useEffect(() => {
    scrollToBottom();
  }, [message, isSelectedAttachment]);

  const { socket, userInfo } = useSocket();

  useEffect(() => {
      socket?.on('connect', () => {
        console.log('A user connected to websocket: ');
        socket.emit('user_connected', { userId: userInfo.id });
      });

      socket?.on('chat_message', (data) => {
        if (data.action === "GET:MESSAGE") {
          dispatch(receievedMessages({
            attachments: data.attachments || [],
            message: data.text,
            senderId: data.from,
            recipientId: data.to,
          }))
        }
      });
  
      socket?.on('disconnect', () => {
        console.log('Disconnected from websocket');
      });

    return () => {
      socket?.off('connect');
      socket?.off('disconnect');
    }
  }, [socket, userInfo, dispatch]);
  
  const onHandleSubmitMessage = (values, props) => {
    const recipientId = chatThread.selectedChatThread.roomId.split("_").find((id) => id !== userInfo.id);

    const formData = new FormData() 

    // FIRST FORM
    // if (values.selectedFile?.length > 0) {
    //   values.selectedFile.map(file => {
    //     if (file instanceof File) {
    //       formData.append("attachments", file)
    //     }
    //     return file
    //   })
    // }

    if (values.selectedFile instanceof File) {
      formData.append("attachments", values.selectedFile)
    }

    formData.append("message", values.content)
    formData.append("senderId", userInfo.id)
    formData.append("recipientId", recipientId)

    dispatch(sendChatMessage(formData)).unwrap().then(() => {
      dispatch(sendMessages({
        message: values.content,
        attachments: [values.selectedFile],
        senderId: userInfo.id,
        recipientId: recipientId,
      }))
    })
    
    props.resetForm();
  }

  const onHandleSelectChatThreads = (selected) => {
    dispatch(selectChatThread(selected))
    dispatch(getChatMessagesByChatThreadId({ chatThreadId: selected.id}))
  }

  // const onHandlePrevNextButton = () => {
  //   console.log(dispatch(logout()))
  // }

  return (
    <Formik
      initialValues={{
        content: "",
        selectedFile: null
      }}
      onSubmit={onHandleSubmitMessage}
    >
    {
      props => {
      return (
        <Form>
          <Row className="g-5">
            <Col xxl="6" xl="12">
              <Row className="p-4 position-relative d-flex flex-column" style={{ background: "#DAD7C8", borderRadius: "20px", height: 750 }}>
                <Loading isLoading={chatThread?.isLoading} text="Loading Chat Threads..."/>
                {/* <Col lg="12">
                  <div className="d-flex justify-content-end align-items-center gap-2">
                    <Image 
                      src={ICONS.leftArrowWithBackgroundCircle} 
                      onClick={onHandlePrevNextButton}
                    />
                    <Image src={ICONS.rightArrowWithBackgroundCircle}/>
                  </div>
                </Col> */}
                <Col lg="12" className="mt-4">
                  <Row style={{ color: "#B88500" }}>
                    <Col>{t("From")}</Col>
                    <Col lg="2">{t("Date")}</Col>
                    <Col lg="2">{t("Time")}</Col>
                  </Row>
                  <div style={{ height: 600, overflowY: "auto", overflowX: "hidden" }}>
                    {
                      chatThread?.list?.map((chatThread, index) => {
                        return (
                          <StyledRowChatThread 
                            className={`border-dark border-bottom`} 
                            key={index} 
                            onClick={() => {
                              props.setFieldValue("selectedFile", null)
                              onHandleSelectChatThreads(chatThread);
                            }}
                          >
                            <Col lg="8" className="">{chatThread.user?.fullname}</Col>
                            <Col lg="2" className="">{moment(chatThread.sendAt).format('D/M/YYYY')}</Col>
                            <Col lg="2" className="">{moment(chatThread.sendAt).format('H:mm')}</Col>
                          </StyledRowChatThread>
                        )
                      })
                    }
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xxl="6" xl="12">
              <Row className="p-4 position-relative g-0" style={{ background: "#DAD7C8", borderRadius: "20px", height: 750  }}>
                <Loading isLoading={message.isLoading} text="Loading Chat Messages..."/>
                {
                  chatThread.selectedChatThread && message.isSuccess && 
                    <>
                      <Col lg="12" className="border-dark border-bottom pb-3 mx-0">
                        <MessageHeader/>
                      </Col>
                      <Col lg="12">
                      
                              <Stack ref={messagesEndRef} className="py-3" gap={4} style={{ overflowY: "auto", overflowX: "hidden", height: 530 }}>
                                {
                                  message.list.map((message, index) => {
                                    return (
                                      <Message message={message} key={index}/>
                                    )
                                  })
                                }
                                {
                                  props.values.selectedFile &&
                                  <div className=" d-flex align-items-center rounded-3">
                                    {
                                      ["audio/wav", "audio/mpeg"].includes(props.values.selectedFile.type) &&
                                      <div>
                                        <audio src={props.values.selectedFile.url} controls/>
                                      </div>
                                    }
                                    {
                                      ["image/jpeg", "image/jpg", "image/png"].includes(props.values.selectedFile.type) &&
                                      <Fragment>
                                        <div className="d-flex gap-2 align-items-center">
                                          <Image src={props.values.selectedFile?.url} alt="genericAvatar" width={100}/>
                                          <span style={{ fontSize: 13 }}>{props.values.selectedFile.path}</span>
                                        </div>
                                      </Fragment>
                                    }
                                    {
                                      [
                                        "application/pdf", 
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                      ].includes(props.values.selectedFile.type) &&
                                      <a href={props.values.selectedFile.url} target="_blank" rel="noopener noreferrer">
                                        {props.values.selectedFile.path}
                                      </a>
                                    }
                                    {
                                      ["video/mp4"].includes(props.values.selectedFile.type) &&
                                        <video height="85" width="126" className="rounded" controls>
                                          <source src={props.values.selectedFile?.url} type="video/mp4" />
                                        </video>
                                    }
                                    <div className="">
                                      <Button 
                                        variant="transparent"
                                        className="text-dark"
                                        onClick={() => props.setFieldValue("selectedFile", null)}
                                        // onClick={() => setSelectedImage(null)}
                                      >
                                        <svg  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10ZM6.97 6.97C7.11063 6.82955 7.30125 6.75066 7.5 6.75066C7.69875 6.75066 7.88937 6.82955 8.03 6.97L10 8.94L11.97 6.97C12.1122 6.83752 12.3002 6.7654 12.4945 6.76882C12.6888 6.77225 12.8742 6.85097 13.0116 6.98838C13.149 7.12579 13.2277 7.31118 13.2312 7.50548C13.2346 7.69978 13.1625 7.88783 13.03 8.03L11.06 10L13.03 11.97C13.1625 12.1122 13.2346 12.3002 13.2312 12.4945C13.2277 12.6888 13.149 12.8742 13.0116 13.0116C12.8742 13.149 12.6888 13.2277 12.4945 13.2312C12.3002 13.2346 12.1122 13.1625 11.97 13.03L10 11.06L8.03 13.03C7.88783 13.1625 7.69978 13.2346 7.50548 13.2312C7.31118 13.2277 7.12579 13.149 6.98838 13.0116C6.85097 12.8742 6.77225 12.6888 6.76882 12.4945C6.7654 12.3002 6.83752 12.1122 6.97 11.97L8.94 10L6.97 8.03C6.82955 7.88937 6.75066 7.69875 6.75066 7.5C6.75066 7.30125 6.82955 7.11063 6.97 6.97Z" fill="#2E2E2E"/>
                                        </svg>
                                      </Button>
                                    </div>
                                  </div>
                                }
                              </Stack>
                              <Stack direction="horizontal" className="mt-4 position-relative" gap={3}>
                                <MessageFooter {...props} setIsSelectedAttachment={setIsSelectedAttachment}/>
                              </Stack>
                      </Col>
                    </>
                  }
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    }
    </Formik>
  );
}

export default LiveChatPage;

const StyledRowChatThread = styled(Row)`
  cursor: pointer;
  /* height: 30px; */
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover{
    color: ${COLORS.gold};
  }
`