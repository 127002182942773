// IMAGES //
import agrocloud from "assets/images/agrocloud.png";
import signinBackground from "assets/images/signin-background.png";
import paddyCamLogo from "assets/images/paddy-cam-logo.png";
import paddyCamText from "assets/images/paddy-cam-word.png";
import genericProfilePicture from "assets/images/generic-profile-picture.png";
import genericAvatar from "assets/images/generic-avatar.png";
import genericMap from "assets/images/generic-map.png";
import genericPaddyCams from "assets/images/generic-paddycams.png";
import genericSampleImage from "assets/images/generic-sample-image.png";
import genericMap1 from "assets/images/generic-map-1.png";

// ICONS //
import rightIcon from "assets/images/icons/right-icon.png";
import mapMarkerAltIcon from "assets/images/icons/map-marker-alt.png";
import mapMarkerPlustIcon from "assets/images/icons/map-marker-plus.png";
import camera from "assets/images/icons/camera.png";
import gallery from "assets/images/icons/gallery.png";
import gallery1 from "assets/images/icons/gallery1.png";
import news from "assets/images/icons/news.png";
import message from "assets/images/icons/message.png";
import messages from "assets/images/icons/messages.png";
import bell from "assets/images/icons/bell.png";
import bell1 from "assets/images/icons/bell1.png";
import setting from "assets/images/icons/setting.png";
import lock from "assets/images/icons/lock.png";
import language from "assets/images/icons/language.png";
import phone from "assets/images/icons/phone.png";
import logout from "assets/images/icons/logout.png";
import pen from "assets/images/icons/pen.png";
import rightArrowWithBackgroundCircle from "assets/images/icons/right-arrow-with-background-circle.png";
import leftArrowWithBackgroundCircle from "assets/images/icons/left-arrow-with-background-circle.png";
import camera1 from "assets/images/icons/camera1.png";
import microphone from "assets/images/icons/microphone.png";
import paperPlane from "assets/images/icons/paper-plane.png";
import plusCircle from "assets/images/icons/plus-circle.png";
import plusCircleWhite from "assets/images/icons/plus-circle-white.png";
import radioIconCircle from "assets/images/icons/radio-icon-circle.png";
import menu from "assets/images/icons/menu.png";
import cameraMarkerIcon from "assets/images/svg/cameraMarkerIcon.svg";
import stopRecordCircleIcon from "assets/images/svg/stopRecordCircleIcon.svg";

const IMAGES = {
  agrocloud,
  signinBackground,
  paddyCamLogo,
  paddyCamText,
  genericProfilePicture,
  genericAvatar,
  genericMap,
  genericPaddyCams,
  genericSampleImage,
  genericMap1
}

const ICONS = {
  rightIcon,
  mapMarkerAltIcon,
  mapMarkerPlustIcon,
  camera,
  gallery,
  gallery1,
  news,
  message,
  messages,
  bell,
  bell1,
  setting,
  lock,
  language,
  phone,
  logout,
  pen,
  leftArrowWithBackgroundCircle,
  rightArrowWithBackgroundCircle,
  camera1,
  microphone,
  paperPlane,
  plusCircle,
  plusCircleWhite,
  radioIconCircle,
  menu,
  cameraMarkerIcon,
  stopRecordCircleIcon
}

export {
  IMAGES,
  ICONS
}