const indonesian = {
  loginLabel: "Log Masuk",
  registerLabel: "Mendaftar",
  forgotPasswordLabel: "Lupa Kata Laluan",
  usernameLabel: "Nama Pengguna",
  passwordLabel: "Kata Laluan",
  confirmPasswordLabel: "Konfirmasi kata sandi",
  nameLabel: "Nama",
  phoneNumberLabel: "Nombor Telefon",
  postsLabel: "Jawatan",
  welcomeLabel: "Selamat Datang",
  masterdByLabel: "Dikuasai Oleh",
  returnLabel: "Kembali",
  editProfileLabel: "Sunting Profil"
}

export default indonesian;