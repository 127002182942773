import React from 'react'
// import { Button } from 'react-bootstrap'
import { useRoutes } from 'react-router-dom'
import { authProtectedRoutes, publicRoutes } from './routes'
import 'assets/scss/theme.scss'
// import { WebsocketProvider, socket } from 'context/WebsocketContext'

const App = () => {
  const content = useRoutes([
    ...publicRoutes, 
    ...authProtectedRoutes
  ])
  return (
    // <WebsocketProvider value={socket}>
      <React.Fragment>
        {content}
      </React.Fragment>
    // </WebsocketProvider>
  )
}

export default App