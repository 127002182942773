import React from 'react'
import { Badge, Image, Nav } from 'react-bootstrap'
import 'bootstrap/js/dist/dropdown'
import { IMAGES } from '../../../assets/images'
import Button from 'components/UI/Button'
import { useDispatch } from 'store'
import { useEffect } from 'react'
import { getNotifications } from 'store/notifications/actions'
import { useSelector } from 'store'

const Header = ({ onHandleToggle }) => {

  const { chatCount, notifCount } = useSelector(
    (state) => state.notifications
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());
    // // Set up interval timer
    // const intervalId = setInterval(() => {
    // }, 3000); // Interval set to 2 seconds (2000 milliseconds)

    // // Clean up interval timer on component unmount
    // return () => clearInterval(intervalId);
  }, [dispatch]); // Dependency array with dispatch as the dependency
  
  return (
      <Nav 
        className="px-5 py-4 d-flex justify-content-center justify-content-lg-between align-items-center flex-wrap flex-lg-nowrap" 
        style={{ background: "#C7BFB3", borderRadius: "0px 0px 20px 20px" }}
      >
        <Nav>
          <Button variant="none" className="d-md-none text-black" onClick={onHandleToggle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
            </svg>
          </Button>
        </Nav>
        <Nav className="d-flex justify-content-between align-items-center">
          <Nav.Link href="/admin/map-overview" className="d-flex justify-content-center align-items-center flex-wrap flex-md-nowrap">
            <Image src={IMAGES.paddyCamLogo}/>
            <Image src={IMAGES.paddyCamText} className="mt-4"/>
          </Nav.Link>
        </Nav>
        <Nav className="d-flex justify-content-center align-items-center gap-1">
          <Nav.Link href="/admin/live-chat" >
              <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3151 27.5846H28.9303C32.284 27.5846 35 24.8563 35 21.4873V6.09725C35 2.72831 32.284 0 28.9303 0H6.06967C2.71597 0 0 2.72831 0 6.09725V21.4949C0 24.796 2.61093 27.4791 5.8746 27.577V31.873C5.8746 32.8076 6.93998 33.3351 7.67524 32.7623L14.3076 27.5921L14.3151 27.5846ZM8.91318 7.12225H25.9593C26.8596 7.12225 27.5874 7.85331 27.5874 8.75773C27.5874 9.66214 26.8596 10.3932 25.9593 10.3932H8.90568C8.00536 10.3932 7.2776 9.66214 7.2776 8.75773C7.2776 7.85331 8.00536 7.12225 8.90568 7.12225H8.91318ZM8.91318 13.2798H21.2401C22.1404 13.2798 22.8682 14.0109 22.8682 14.9153C22.8682 15.8197 22.1404 16.5507 21.2401 16.5507H8.90568C8.00536 16.5507 7.2776 15.8197 7.2776 14.9153C7.2776 14.0109 8.00536 13.2798 8.90568 13.2798H8.91318Z" fill="#3d3f22"/>
              </svg>
            {
              chatCount.length > 0 ? 
              <Badge bg="danger">{chatCount[0]?.count || "0"}</Badge>
              : <></>
            }
          </Nav.Link>
          <Nav.Link>
            <svg width="29" height="33" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5061 33C12.1768 33 10.365 31.1953 10.365 28.875H18.5825C18.5825 31.1953 16.7708 33 14.5061 33ZM28.4177 23.3965C28.8059 23.7832 29 24.2988 29 24.75C28.9353 25.8457 28.1588 26.8125 26.8647 26.8125H2.08281C0.788719 26.8125 0.0122616 25.8457 0.0122616 24.75C-0.0524432 24.2988 0.141671 23.7832 0.5299 23.3965C1.75929 22.043 4.15337 20.0449 4.15337 13.4062C4.15337 8.44336 7.64742 4.44727 12.4356 3.41602V2.0625C12.4356 0.966797 13.3414 0 14.5061 0C15.6061 0 16.512 0.966797 16.512 2.0625V3.41602C21.3001 4.44727 24.7942 8.44336 24.7942 13.4062C24.7942 20.0449 27.1883 22.043 28.4177 23.3965Z" fill="#3d3f22"/>
            </svg>
            {
              notifCount.length > 0 ? 
              <Badge bg="danger">{notifCount[0]?.count || "0"}</Badge>
              : <></>
            }
          </Nav.Link>
          {/* <Nav.Link href="/admin/live-chat" >
            <Image 
              src={ICONS.message}
              style={{ 
                filter: "brightness(0%) sepia(100%) hue-rotate(180deg) saturate(800%)",
              }}
            />
          </Nav.Link>
          <Nav.Link href="/admin/equipment-information" >
            <Image src={ICONS.bell}
              style={{ 
                filter: "brightness(0%) sepia(100%) hue-rotate(180deg) saturate(800%)",
              }}
            />
            {
            list.length > 0 ? 
            <Badge bg="danger">{list[0]?.count || "0"}</Badge>
            // data?.map((notification) => {
            //   return (
            //     <Fragment key={notification.id}>
            //       <Badge bg="danger">{notification.count}</Badge>
            //     </Fragment>
            //   )
            // })
            : <></>
          }
          </Nav.Link> */}
        </Nav>
      </Nav>
    // <Container fluid>
    //   <Row>
    //     <Col lg="12">
    //       <div className="d-flex justify-content-center align-items-center" style={{ background: "#C7BFB3", height: 126, borderRadius: "0px 0px 20px 20px" }}>
    //         <Image src="/images/paddy-cam-logo.png"/>
    //         <Image src="/images/paddy-cam-word.png" className="mt-4"/>
    //       </div>
    //     </Col>
    //   </Row>
    // </Container>
  )
}

export default Header