const COLORS = {
  primary: "#BEC6AB", // paleGreen
  secondary: "#EAE3D7", // offWhite
  tertiary: "#F2EDE8", // offWhite
  
  grey: "#D9D7C6", // cream > from yellow
  grey1: "#CDCDCD", // lightGrey > from red
  grey2: "#C7BFB3", // beige from orange
  grey3: "#BCC4A9", // sage from green
  grey4: "#ADB3A1", // moss from green

  grey5: "#A2A2A2", // grey > from red
  grey6: "#939A80", // olive > from green
  grey7: "#85837F", // taupe > from orange
  grey8: "#79766E", // warmGrey > from orange
  grey9: "#8F8E72", // khaki > from yellow green

  dark: "#464A1F", // darkOlive > from yellow green
  dark1: "#45491F", // deepOlive > from yellow green
  dark2: "#52514E", // charcoal > from orange

  gold: "#B88500", // gold > from orange
};

const SIZES = {
  extraSmall: 7,
  small: 8,
  smallMeduim: 10,
  smallLarge: 12,
  smallXl: 15,
  smallXll: 16,
  medium: 18,
  mediumLarge: 20,
  large: 22,
  extraLarge: 25,
  xxl: 28,
  xxxl: 40,
  huge: 62,
}

const RADIUS = {
  small: 9,
  smallMeduim: 10,
  medium: 20,
  large: 100,
}

export  {
  COLORS,
  SIZES,
  RADIUS
}