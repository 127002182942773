import { Alert, Button, Col, Container, FormGroup, Image, Row, Stack } from "react-bootstrap"
import { COLORS } from "constant/themes"
import { Form, Formik } from "formik"
import styled from "styled-components";
import { ICONS, IMAGES } from "assets/images";
import translateLanguange from "helpers/getTranslations";
import * as Yup from "yup"
import { useDispatch } from "store";
import { useState } from "react";
import { useSelector } from "store";
import InputField from "components/UI/Forms/InputField";
import { useTranslation } from "react-i18next";
import { registerFarmerAccountUsingEmail } from "store/user/actions";
import Loading from "components/UI/Loading";
import { forceClearAllUserState } from "store/user/reducer";
import ErrorModal from "components/UI/ErrorModal";
import SuccessModal from "components/UI/SuccessModal";

const FarmerAccountsPage = () => {
  const { t } = useTranslation();
  const { success, isLoading, error } = useSelector(
    (state) => state.user
  );
 
  const dispatch = useDispatch();
  
  const [validationSchema] = useState(
    Yup.object().shape({
      email: Yup.string().email('Username must be in email format').required(`${t("Please Input Username")}`),
      password: Yup.string().required(`${t("Please Input Password")}`),
    })
  );

  const onHandleRegister = (values, props) => {
    let payload = { 
      // firstName: values.firstName, 
      // lastName: values.lastName, 
      fullname: values.fullname,
      email: values.email.trim(), 
      password: values.password.trim() 
    };
    dispatch(registerFarmerAccountUsingEmail(payload)).unwrap().then(() => props.resetForm());
  }

  // useEffect(() => {
  //   if (success) {
  //     dispatch(forceClearAllUserState())
  //   }
  // }, [success, dispatch])
  

  return (
    <Container fluid>
      <SuccessModal 
        onCancel={() => { dispatch(forceClearAllUserState()) }} 
        onOk={() => { dispatch(forceClearAllUserState()) }} 
        isShow={success} 
        title={`${t("Success")}!`} 
        description={`${t("Successfully Created Farmer Account")}`}
      />
      <ErrorModal 
        isShow={error} 
        title="Error!" 
        description={error} 
        onOk={() => {
          dispatch(forceClearAllUserState())
        }}
      />
      <Loading isLoading={isLoading} text="Creating Farmer Account..."/>
      <Row className="h-sm-100" style={{ height: "100vh" }}>
        <Col xxl="7" xl="12" className="d-flex flex-column align-items-center justify-content-around" style={{ background: COLORS.secondary }}>
          <Container>
            <Row className="justify-content-md-center">
              <Col lg="9">
                <Formik
                  validationSchema={validationSchema}
                  onSubmit={onHandleRegister}
                  initialValues={{
                    email: "",
                    password: "",
                    // firstName: "",
                    // lastName: "",
                    fullname: "",
                  }}
                >
                  {props => {
                    return (
                      <Form className="h-100 d-flex flex-column align-items-center justify-content-center">
                        <Stack gap={3}>
                          <Stack gap={5}>
                            <Stack className="d-flex justify-content-center" direction="horizontal" gap={4}>
                              <h1 style={{ textDecoration: "none", color: "#8F8E72" }}>{t("Create Farmer Account")}</h1>
                            </Stack>
                              {
                                error &&
                                <Alert variant="danger">
                                  <span className="text-danger">{error}</span>
                                </Alert>
                              }
                              <InputField
                                controlId="formFullname"
                                type="text"
                                name="fullname"
                                placeholder={t("Fullname")}
                                {...props} 
                              />
                              <InputField
                                controlId="formEmail"
                                type="text"
                                name="email"
                                required={true}
                                placeholder={t("Username")}
                                {...props} 
                              />
                              <InputField
                                controlId="formPassword"
                                type="password"
                                name="password"
                                required={true}
                                placeholder={t("Password")}
                                {...props} 
                              />
                          </Stack>
                          <FormGroup className="d-flex justify-content-center">
                            <StyledButton className="rounded-circle" variant="dark" type="submit">
                              <Image src={ICONS.rightIcon} height={33.76} width={29.29}/>
                            </StyledButton>
                          </FormGroup>
                        </Stack>
                      </Form>
                    )
                  }}
                </Formik>
              </Col>
            </Row>
          </Container>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span>{translateLanguange().masterdByLabel}</span>
            <Image src={IMAGES.agrocloud} width={201}/>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default FarmerAccountsPage

const StyledButton = styled(Button)`
  border: none;
  background: #45491F;
  height: 54px;
  width: 54px;
`