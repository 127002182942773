import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

const PublicLayout = ({ children }) => {
  return (
    <Fragment>
      <Outlet/>
      {children}
    </Fragment>
  )
}

export default PublicLayout