const english = {
  loginLabel: "Login",
  registerLabel: "Register",
  forgotPasswordLabel: "Forgot a password",
  usernameLabel: "Username",
  passwordLabel: "Password",
  nameLabel: "Name",
  phoneNumberLabel: "Nombor Telefon",
  postsLabel: "Posts",
  welcomeLabel: "Welcome",
  masterdByLabel: "Mastered By",
  returnLabel: "Return",
  editProfileLabel: "Edit Profile"
}

export default english;