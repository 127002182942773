// import { useNavigate } from "react-router-dom";
import { useDispatch } from "store";
import { cleartAuthState } from "store/auth/reducer";

const useSignOut = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  return () => {
    dispatch(cleartAuthState());
    window.location.href = "/signin"
    // navigate("/signin");
  };
};

export { useSignOut };