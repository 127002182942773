import { ICONS, IMAGES } from 'assets/images';
import MapContainer from 'components/Layout/Maps';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import { COLORS, SIZES } from 'constant/themes';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Accordion, Col, Image, Row, Stack } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { useDispatch } from 'store';
import { getRegisterEquipments } from 'store/equipments/actions';
import { setSelectedEquipment, setSelectedDevice, setSelectedCoordinates } from 'store/equipments/reducer';
import styled from 'styled-components';


const MapOverViewPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { equipments } = useSelector(
    (state) => state.equipments
  );

  const coordinates = equipments.coordinates;

  const [activeKey, setActiveKey] = useState("0");
  
  const dispatch = useDispatch();

  const getEquipments = () => {
    dispatch(getRegisterEquipments(`?additionalInfo=1`));
  }

  useEffect(() => {
    getEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggle = (index) => {
    setActiveKey(activeKey === index ? null : index);
  };

  const onClickHeaderAccordion = (item, index) => {
    dispatch(setSelectedCoordinates(item.coordinates));
    if (!activeKey) {
      dispatch(setSelectedEquipment(item));
    }
  }

  const onHandleViewImageDevice = (device, equipment) => {
    dispatch(setSelectedEquipment(equipment));
    dispatch(setSelectedDevice(device));
    navigate("/admin/equipment-information");
  }

  useEffect(() => {
    if (activeKey && equipments.list[Number(activeKey)] && equipments.list[Number(activeKey)].equipments) {
      dispatch(setSelectedEquipment(equipments.list[Number(activeKey)]));
      dispatch(setSelectedDevice(equipments.list[Number(activeKey)].equipments[0]));
    } else {
      dispatch(setSelectedDevice(null));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, equipments.list[0]])
  
  return (
    <Row className="g-5">
      <Col xxl="8" xl="12">
        <Row className="position-relative" style={{ borderRadius: "20px", height: 720 }}>
          <Loading isLoading={equipments.isLoading} text="Loading Map..."/>
          <MapContainer coordinates={coordinates} isCustomMarker={true} onHandleClickMarker={(equipment) => onHandleViewImageDevice(equipment.equipments[0], equipment)}/>
        </Row>
      </Col>
      <Col xxl="4" xl="12">
        <Row 
          className="position-relative gap-4 g-2 d-flex flex-column p-3 justify-content-between" 
          style={{ 
            background: "#F2EDE8", 
            borderRadius: "20px", 
            // height: equipments.isLoading ? "80vh" : "inherit"
            // height: equipments.isLoading ? "80vh" : "inherit"
          }}
        >
          <Loading isLoading={equipments?.isLoading}/>
          <Col lg="12">
            <StyledAccordion 
              defaultActiveKey={activeKey} 
              onSelect={(index) => handleToggle(index)}
            >
              {
                equipments?.list.map((item, index) => {
                  return (
                    <StyledAccordionItem eventKey={index.toString()} key={index}>
                      <Accordion.Header onClick={() => onClickHeaderAccordion(item, index)}>
                        
                        <span style={{ fontSize: SIZES.smallXl }}><span style={{ fontSize: SIZES.medium }}>{item.name}</span> ({item.equipments.length} {t("Image")}s)</span>
                        {/* Paddycam PC0{index + 1} (2 Gambar) */}
                        {
                          activeKey === index.toString() ? 
                          <div 
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 100, background: "#45491F", height: 26, width: 26 }}
                          >
                            <div style={{ border: "none", borderRadius: 100, boxShadow: "none" }}>
                              <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.04419 9.22639C7.04419 9.22639 7.08848 9.22639 7.11063 9.22639C7.13277 9.22639 7.15492 9.21162 7.17707 9.20424C7.20659 9.19686 7.23612 9.18209 7.25827 9.17471C7.28042 9.16733 7.29518 9.15257 7.31733 9.14518C7.32471 9.14518 7.33209 9.14518 7.33947 9.13042C7.35424 9.12304 7.35424 9.10827 7.369 9.10089C7.39115 9.07874 7.41329 9.0566 7.43544 9.03445C7.44282 9.01969 7.45759 9.01969 7.46497 9.00492L13.1935 1.19465C13.3854 0.928894 13.3264 0.56717 13.068 0.367853C12.8022 0.175918 12.4405 0.234975 12.2412 0.493349L7.58308 6.84935L6.99251 7.67614L6.40194 6.84935L1.74383 0.493349C1.5519 0.227593 1.18279 0.175918 0.917036 0.367853C0.65128 0.559788 0.599606 0.928894 0.79154 1.19465L6.52006 9.00492C6.52006 9.00492 6.54221 9.01969 6.54959 9.03445C6.57173 9.0566 6.59388 9.07874 6.61603 9.10089C6.63079 9.10827 6.63079 9.12304 6.64555 9.13042C6.65294 9.13042 6.66032 9.13042 6.6677 9.14518C6.68985 9.15995 6.70461 9.16733 6.72676 9.17471C6.75629 9.18948 6.77843 9.19686 6.80796 9.20424C6.83011 9.21162 6.85225 9.219 6.8744 9.22639C6.89655 9.22639 6.91869 9.22639 6.94084 9.22639C6.9556 9.22639 6.97037 9.23377 6.98513 9.23377C6.9999 9.23377 7.01466 9.22639 7.02942 9.22639L7.04419 9.22639Z" fill="white"/>
                              </svg>
                            </div>
                          </div> : 
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 100, background: "#45491F", height: 26, width: 26 }}>
                          <div 
                            style={{ border: "none", borderRadius: 100, boxShadow: "none" }}
                          >
                              <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.95581 0.773583C6.95581 0.773583 6.91152 0.773583 6.88937 0.773583C6.86723 0.773583 6.84508 0.788348 6.82293 0.795729C6.79341 0.803112 6.76388 0.817876 6.74173 0.825257C6.71958 0.832639 6.70482 0.847404 6.68267 0.854787C6.67529 0.854787 6.66791 0.854787 6.66053 0.869551C6.64576 0.876933 6.64576 0.891697 6.631 0.899079C6.60885 0.921225 6.58671 0.943372 6.56456 0.965518C6.55718 0.980282 6.54241 0.980283 6.53503 0.995047L0.806513 8.80532C0.614579 9.07108 0.673636 9.4328 0.932009 9.63212C1.19777 9.82405 1.55949 9.76499 1.75881 9.50662L6.41692 3.15062L7.00749 2.32383L7.59806 3.15062L12.2562 9.50662C12.4481 9.77238 12.8172 9.82405 13.083 9.63212C13.3487 9.44018 13.4004 9.07108 13.2085 8.80532L7.47994 0.995047C7.47994 0.995047 7.45779 0.980282 7.45041 0.965518C7.42827 0.943371 7.40612 0.921225 7.38397 0.899079C7.36921 0.891697 7.36921 0.876933 7.35445 0.869551C7.34706 0.869551 7.33968 0.86955 7.3323 0.854787C7.31015 0.840022 7.29539 0.832639 7.27324 0.825257C7.24371 0.810493 7.22157 0.803112 7.19204 0.795729C7.16989 0.788348 7.14775 0.780965 7.1256 0.773583C7.10345 0.773583 7.08131 0.773583 7.05916 0.773583C7.0444 0.773583 7.02963 0.766201 7.01487 0.766201C7.0001 0.766201 6.98534 0.773583 6.97057 0.773583L6.95581 0.773583Z" fill="white"/>
                              </svg>
                            </div>
                          </div>
                        }
                      </Accordion.Header>
                      <Accordion.Body style={{ maxHeight: '25vh', overflowY: 'auto',  overflowX: "hidden" }}>
                        <Stack gap={2}>
                          {
                            item.equipments?.map((equipment, keyEquipment) => {
                              return (
                                  <StyledCard key={keyEquipment}>
                                    <Card.Body 
                                      style={{ borderBottom: "2px solid black", cursor: "pointer" }}
                                      className="rounded-2 d-flex gap-2 align-items-center justify-content-between"
                                    >
                                      <span style={{ fontSize: SIZES.smallMeduim }}>{t("Image")} {keyEquipment + 1}</span>
                                      <div className="d-flex flex-column" style={{ fontSize: SIZES.extraSmall }}>
                                        {t("Caught In")}
                                        <span style={{ fontSize: SIZES.smallMeduim }}>
                                          {moment(equipment.capturedAt).format('DD/MM/YYYY')}
                                        </span>
                                      </div>
                                      <span style={{ fontSize: SIZES.smallMeduim }}>
                                        {moment(equipment.capturedAt).format('HH:mm:ss')}
                                      </span>
                                      <Button
                                        className="pb-1 pt-0 px-3 d-flex gap-2 rounded-pill"
                                        variant={COLORS.dark}
                                        iconLeft={<span style={{ fontSize: SIZES.small}}>{t("View Image")}</span>}
                                        iconRight={<Image src={ICONS.gallery1}/>}
                                        onClick={() => onHandleViewImageDevice(equipment, item)}
                                      >
                                        {equipment.image}
                                      </Button>
                                    </Card.Body>
                                  </StyledCard>
                              )
                            })
                          }
                        </Stack>
                      </Accordion.Body>
                    </StyledAccordionItem>
                  )
                })
              }
            </StyledAccordion>
          </Col>
          {
            equipments?.selectedDevice &&
            <>
              <Row>
                <Col lg="12">
                  <Row className="g-3">
                    <Col lg="12">
                      <Row className="px-3">
                        <Col lg="4"  style={{ borderRight: `1px solid ${COLORS.grey7}`}}>
                          <Stack>
                            <span style={{ fontSize: SIZES.smallLarge, color: COLORS.grey7 }}>{t("Paddycam")}</span>
                            <span style={{ fontSize: SIZES.smallMeduim }}>{equipments?.selectedDevice.deviceName}</span>
                          </Stack>
                        </Col>
                        <Col lg="4" style={{ borderRight: `1px solid ${COLORS.grey7}`}}>
                          <Stack>
                            <span style={{ fontSize: SIZES.smallLarge, color: COLORS.grey7 }}>{t("Image")}</span>
                            <span style={{ fontSize: SIZES.smallMeduim }}>{t("Image")} 1</span>
                          </Stack>
                        </Col>
                        <Col>
                          <Stack>
                            <span style={{ fontSize: SIZES.smallLarge, color: COLORS.grey7 }}>{t("Caught By")}</span>
                            <span style={{ fontSize: SIZES.smallMeduim }}>{equipments?.selectedDevice.capturedBy}</span>
                          </Stack>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="g-3" lg="12" style={{ height: 188 }}>
                      <Image fluid src={equipments?.selectedDevice.capturedPhoto || IMAGES.genericSampleImage} className="w-100 h-100 rounded-3" style={{ objectFit: "cover"}}/>
                    </Col>
                    <span style={{ fontSize: SIZES.smallLarge, color: COLORS.grey7 }}>
                      {moment(equipments?.selectedDevice?.capturedAt).format('DD')} {" "}
                      {t(moment(equipments?.selectedDevice?.capturedAt).format('MMMM'))} {" "}
                      {moment(equipments?.selectedDevice?.capturedAt).format('YYYY')} {" "}
                      {moment(equipments?.selectedDevice?.capturedAt).format('HH:mm:ss')} {" "}
                    </span>
                  </Row>
                </Col>
              </Row>
            </>
          }
        </Row>
      </Col>
    </Row>
  )
}

export default MapOverViewPage

const StyledAccordion = styled(Accordion)`
  background-color: none;
  height: 70vh;
  overflow-y: auto;
  .accordion-item {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .accordion-header {
    border-bottom: 1.5px solid #ADB3A1;

    .accordion-button {
      outline: none !important;
      box-shadow: none !important;
    }

    .accordion-button::after {
      display: none;
    }
  }

  .accordion-button {
    background-color: #F2EDE8;
    border:none;

    display: flex;
    justify-content:space-between;
  }

  .accordion-body {
    background-color: #F2EDE8;
    padding: 15px 0px;
    border: none;

  }

  .accordion-collapse {
  }
  
`

const StyledAccordionItem = styled(Accordion.Item)`

  &:last-of-type {
    border-radius: none !important;
  }
`

const StyledCard = styled(Card)` 
  border:none;
  background-color:#BEC6AB;
  border-radius: 8px;
`