import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import styled from 'styled-components';

const defaultVariantBootstrap = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link'];

const Button = ({ iconLeft, children, iconRight, style, type="button", className, variant, size, onClick, disabled }) => {
  return (
    <StyledBootstrapButton
      className={`d-flex align-items-center ${iconLeft && iconRight ? 'justify-content-between' : 'justify-content-center'} ${className}`}
      style={{ background: `${defaultVariantBootstrap.includes(variant) ? "" : variant}`, color: "#fff", ...style}}
      type={type}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
    >
      {iconLeft && <span className="icon-left">{iconLeft}</span>}
      {children && <span className={`flex-grow-1 text-center text-label ${iconLeft && ''} ${iconRight && ''}`}>{children}</span>} 
      {iconRight && <span className="icon-right">{iconRight}</span>}
    </StyledBootstrapButton>
  );
};

export default Button;

const StyledBootstrapButton = styled(BootstrapButton)`
  outline: none;
  box-shadow: none !important;
`;