import axios from "axios";

import { store } from "store";
import { getCookie } from "./cookies";
import { cleartAuthState } from "store/auth/reducer";
const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || "https://develop.paddy-cam-api.highlysucceed.com/api/v1", // Rpeplace with your API base URL
  timeout: Number(process.env.REACT_APP_API_TIMEOUT) || 10000, // Adjust the timeout as needed
};
const axiosInstance = axios.create(apiConfig);

// Token handling interceptor -----------------------------
axiosInstance.interceptors.request.use((config) => {

  const tokenFromCookies = getCookie("signinUserData")
    ? getCookie("signinUserData").token
    : null;
  const token = store?.getState().auth?.token ?? tokenFromCookies;
  if (token !== null) {
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["ngrok-skip-browser-warning"] = "69420"; // TEMPORARY REMOVE ASAP
    return config;
  } else {
    return config;
  }
});

//Cancelling Request ---------------------------------------
const cancelInMillisecond = 120000; // default 10000

// Error handling interceptor ------------------------------
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const errorResponse = {};

    if (error.response) {
      // The request was made and the server responded with a status code
      errorResponse.status = error.response.status;
      errorResponse.message = error.response.data ?? "Response Error";
      errorResponse.data = error.response.data;
    } else if (error.request) {
      console.log("ERROR: ", error);

      // The request was cancelled
      if (error?.config?.signal?.aborted) {
        errorResponse.message = `Request took longer than ${cancelInMillisecond} second`;
      } else {
        // The request was made but no response was received
        errorResponse.message = "Request Error";
      }
    } else {
      // Something happened in setting up the request
      errorResponse.message = "General Error";
      errorResponse.data = error.message;
    }

    const isTokenExpired = errorResponse.message.m === "Unauthorized";
    if (isTokenExpired) {
      store?.dispatch(cleartAuthState({ errorMessage: errorResponse.message.m }));
      // const currentCookie =
      //   getCookie("signinUserData") && getCookie("signinUserData");
      // console.log("TOKEN EXPIRED, YOU WILL BE ASK TO RELOGIN", currentCookie);

      // setCookie(
      //   "signinUserData",
      //   {
      //     ...currentCookie,
      //     expiredAt: 0,
      //   }
      // );
    }
    return Promise.reject(errorResponse);
  }
);

// API methods ---------------------------------------------
const apiService = {
  // GET request
  get: (url, params) =>
    axiosInstance.get(url, {
      ...params,
      signal: AbortSignal.timeout(cancelInMillisecond),
    }),

  // POST request
  post: (url, data, config = {}) =>
    axiosInstance.post(url, data, {
      ...config,
      signal: AbortSignal.timeout(cancelInMillisecond),
    }),

  // PUT request
  put: (url, data, config = {}) =>
    axiosInstance.put(url, data, {
      ...config,
      signal: AbortSignal.timeout(cancelInMillisecond),
    }),

  // DELETE request
  delete: (url, config = {}) =>
    axiosInstance.delete(url, {
      ...config,
      signal: AbortSignal.timeout(cancelInMillisecond),
    }),
};

export default apiService;
