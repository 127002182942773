import { createSlice } from "@reduxjs/toolkit";
import {
  getChatMessagesByChatThreadId,
  getChatThreadsByAdmin,
  sendChatMessage,
} from "./actions";

const initialState = {
  chatThread: {
    list: [],
    selectedChatThread: null,
    isLoading: false,
    message: "",
    isError: false,
    isSuccess: false,
  },

  message: {
    list: [],
    selectedMessage: null,
    isLoading: false,
    message: "",
    isError: false,
    isSuccess: false,
    isSendMessageLoading: false
  },

  isLoading: false,
  error: null,
  success: null,
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    clearChatsState: (state, action) => {
      state.data = null;
      state.token = null;
      state.error = null;
      state.success = null;
    },
    selectChatThread: (state, action) => {
      state.chatThread.selectedChatThread = action.payload
    },
    sendMessages: (state, action) => {
      state.message.list = [...state.message.list, action.payload]
    },
    receievedMessages: (state, action) => {
      const recipientId = state.chatThread.selectedChatThread?.roomId.split("_")[0] === action.payload.senderId; // Push message if the user is === recipientId

      if (recipientId) {
        state.message.list = [...state.message.list, action.payload]
      }
    },
    resetAllchatsSliceState: () => initialState, // Reset state to initial state
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatThreadsByAdmin.pending, (state) => {
        state.chatThread.isLoading = true;
      })
      .addCase(getChatThreadsByAdmin.fulfilled, (state, action) => {
        state.chatThread.isLoading = false;
        state.chatThread.isSuccess = true;
        state.chatThread.list = action.payload.d
        state.chatThread.message = "Successfully get chat threads";
      })
      .addCase(getChatThreadsByAdmin.rejected, (state, action) => {
        state.chatThread.isLoading = false;
        state.chatThread.error = action.payload.m || "An error occurred";
      })

      .addCase(getChatMessagesByChatThreadId.pending, (state) => {
        state.message.isLoading = true;
      })
      .addCase(getChatMessagesByChatThreadId.fulfilled, (state, action) => {
        state.message.isLoading = false;
        state.message.isSuccess = true;
        state.message.list = action.payload.d
        state.message.message = "Successfully get messages by chat thread id";
      })
      .addCase(getChatMessagesByChatThreadId.rejected, (state, action) => {
        state.message.isLoading = false;
        state.message.message = action.payload.m || "An error occurred";
      })

      .addCase(sendChatMessage.pending, (state) => {
        state.message.isSendMessageLoading = true;
      })
      .addCase(sendChatMessage.fulfilled, (state, action) => {
        state.message.isSendMessageLoading = false;
        state.message.isSuccess = true;
        state.message.message = "Successfully send a message";
      })
      .addCase(sendChatMessage.rejected, (state, action) => {
        state.message.isSendMessageLoading = false;
        state.message.message = action.payload.m || "An error occurred";
      })
      
  },
});

export const { 
  clearChatsState, 
  selectChatThread, 
  logout,
  receievedMessages,
  sendMessages
} = chatsSlice.actions;

// export const clearChatsState = (payload) => (dispatch) => {
//   dispatch(chatsSlice.actions.clearChatsState(payload));
// };

// export const selectChatThread = (payload) => (dispatch) => {
//   dispatch(chatsSlice.actions.selectChatThread(payload));
// };


export default chatsSlice.reducer;
