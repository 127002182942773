import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/apiService";

export const getNewsByAdmin = createAsyncThunk(
  "news/getNewsByAdmin",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/news`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error fetching news");
    }
  }
);

export const publishNewsByAdmin = createAsyncThunk(
  "news/publishNewsByAdmin",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.post(`/admins/news`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error fetching news");
    }
  }
);
