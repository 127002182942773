import { ICONS } from 'assets/images';
import Button from 'components/UI/Button';
import ErrorModal from 'components/UI/ErrorModal';
import InputField from 'components/UI/Forms/InputField';
import Loading from 'components/UI/Loading';
import SuccessModal from 'components/UI/SuccessModal';
import { COLORS, RADIUS, SIZES } from 'constant/themes';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Alert, Col, FormCheck, FormControl, FormGroup, Image, Row, Stack } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { useDispatch } from 'store';
import { getDevicesByEquipmentId, getRegisterEquipments, sendInformtionToTheApp } from 'store/equipments/actions';
import { setModalClose, setSelectedDevice, setSelectedEquipment } from 'store/equipments/reducer';
import styled from 'styled-components';

const ACTIONS = [
  {name: 'Spray Required', value: "0" },
  {name: 'According to IADA Scheduling', value: "1" },
  {name: 'Spraying is not Encouraged', value: "2" },
  {name: 'Change Insect Stickers', value: "3" },
]

const transformInitialValuesForm = (prevState, data) => {
  let formatDate = moment(data?.actionReminder).format("MM-DD-YYYY").split("-").join("").split("");
  if (isNaN(moment(data?.actionReminder))) {
    formatDate = ["", "", "", "", "", "", "", "",]
  }

  return {
    ...prevState,
    action: data?.action && ACTIONS.find(action => action.name === data.action)?.value,
    ...data?.actionReminder && {
      date: {
        month1: formatDate[0], month2: formatDate[1], 
        day1: formatDate[2], day2: formatDate[3], 
        year1: formatDate[4], year2: formatDate[5], year3: formatDate[6], year4: formatDate[7]
      }
    },
    identifiedInsects: data?.identifiedInsects,
  }
}

const InsectCountingPage = () => {
  const { t } = useTranslation();
  const [selectedIndexCarousel, setSelectedIndexCarousel] = useState(0);
  const [isShowDropdownListEquipment, setIsShowDropdownListEquipment] = useState(false);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState("Day Month Year");
  const [isShowCalendarFilter, setIsShowCalendarFilter] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    action: 2,
    identifiedInsects: [
      {
        type: "",
        quantity: "",
      }
    ],
    date: {
      month1: '', month2: '', 
      day1: '', day2: '', 
      year1: '', year2: '', year3: '', year4: ''
    }
  })
 
  const { equipments, devices } = useSelector(
    (state) => state.equipments
  );

  const [isSuccessSubmitSendInformation, setIsSuccessSubmitSendInformation] = useState(false);
  
  const dispatch = useDispatch();
  
  const getEquipments = async () => {
    const result = await dispatch(getRegisterEquipments(`?additionalInfo=1`));
    const data = result.payload.d
      dispatch(setSelectedEquipment(equipments.selectedEquipment || data[0]));
      setSelectedIndexCarousel(equipments.selectedDevice?.selectedDeviceIndex || Number(selectedIndexCarousel))
      dispatch(getDevicesByEquipmentId({ deviceId: equipments.selectedDevice?.deviceId || data[0].id })).unwrap().then(res => {
        const data = dispatch(setSelectedDevice(res.d[equipments.selectedDevice?.selectedDeviceIndex] || res.d[Number(selectedIndexCarousel)]));
        setInitialFormValues((prevState) => transformInitialValuesForm(prevState, data.payload))
      })
  }

  useEffect(() => {
    getEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSubmitSendInformation])

  const refs = {
    month1: useRef(null),
    month2: useRef(null),
    day1: useRef(null),
    day2: useRef(null),
    year1: useRef(null),
    year2: useRef(null),
    year3: useRef(null),
    year4: useRef(null),
  };

  const handleChange = (props, field) => {
    const regex = /^[0-9]*$/;

    if (regex.test(refs[field].current.value)) {
      console.log('object');
      if (props.values.date[field].length <= 1 && !isNaN(props.values.date[field])) {
        props.setValues({
          ...props.values,
          date: {
            ...props.values.date,
            [field]: refs[field].current.value
          }
        });
        // Move to the next input field
        const nextField = Object.keys(refs)[Object.keys(refs).indexOf(field) + 1];
        if (props.values.date && nextField) {
          refs[nextField].current.focus();
        }
      }
      console.log(props.values, field, refs[field].current.value)
  
      // setValue(newValue);
    }

    
  };

  const handleKeyDown = (e, props, field) => {
    if (e.key === 'Backspace' && !refs[field].current.value) {
      // Move to the previous input field
      const prevField = Object.keys(refs)[Object.keys(refs).indexOf(field) - 1];
      if (prevField) {
        refs[prevField].current.focus();
      }
    }
  };

  const handleSelectNextPrevCarousel = (selectedIndex) => {
    setSelectedIndexCarousel(selectedIndex);
    const { payload } = dispatch(setSelectedDevice(devices.list[Number(selectedIndex)] || equipments.selectedDevice));
    setInitialFormValues((prevState) => transformInitialValuesForm(prevState, payload))

  };

  const handleSubmit = async (values, props) => {
    const { month1, month2, day1, day2, year1, year2, year3, year4  } = values.date;

    const formattedDate = `${month1}${month2}-${day1}${day2}-${year1}${year2}${year3}${year4}`;

    const payload = {
      action: values.action,
      actionReminder: formattedDate,
      identifiedInsects: values.identifiedInsects,
    }
    await dispatch(sendInformtionToTheApp({ equipmentId: equipments.selectedDevice?.id, payload })).unwrap().then(res => {
      setIsSuccessSubmitSendInformation(true);
      props.resetForm()
    });
  }

  const onHandleShowSelecOption = () => {
    setIsShowDropdownListEquipment((prevState) => !prevState)
  };

  const onHandleSelectEquipment = (equipment) => {
      // window.prompt("sdas")
    setIsShowDropdownListEquipment((prevState) => !prevState)
    dispatch(setSelectedEquipment(equipment));
    dispatch(getDevicesByEquipmentId({ deviceId: equipment.equipments[0]?.deviceId })).unwrap().then(res => {
      const data = dispatch(setSelectedDevice(res.d[equipments.selectedDevice?.selectedDeviceIndex] || res.d[Number(selectedIndexCarousel)]));
      setInitialFormValues((prevState) => transformInitialValuesForm(prevState, data.payload))
    }).catch(err => {
      setError("ERROR " + err.m)
      setTimeout(() => {
        setError("")
       }, 3000);
    });
  };

  const onHandleFilterDate = (date) => {
    setIsShowCalendarFilter((prevState) => !prevState)
    dispatch(getDevicesByEquipmentId({ deviceId: equipments.selectedDevice?.deviceId || equipments?.selectedEquipment?.id, query: date ? `&capturedDate=${moment(date).format('YYYY-MM-DD')}` : "" }))
    .unwrap()
    .then(res => {
      setSelectedIndexCarousel(0);
      setInitialFormValues((prevState) => transformInitialValuesForm(prevState, res.d[0]))
      dispatch(setSelectedDevice(res.d[0]));
    });
    // dispatch(getRegisterEquipments(`?additionalInfo=1&capturedDate=${moment(date).format('YYYY-MM-DD')}`));
    setSelectedDate(moment(date).format('DD MM YYYY'));
  };

  return (
    <Row className="g-5 position-relative">
      <SuccessModal 
        isShow={equipments?.isSuccessSendInformationToTheApp}
        title={`${t("Success")}!`}
        description={t("Successfully send Information to the app")} 
        onOk={() => {
          setIsSuccessSubmitSendInformation(false);
          dispatch(setModalClose())
        }}
        onCancel={() => {
          setIsSuccessSubmitSendInformation(false);
          dispatch(setModalClose())
        }}
      />
      <ErrorModal 
        isShow={equipments?.isErrorSendInformationToTheApp} 
        title="Error!" 
        description={equipments.message} 
        onOk={() => {
          dispatch(setModalClose())
        }}
      />
      <Col lg="12">
        {
          error && 
          <Alert key={"danger"} variant={"danger"}>
            {error}
          </Alert>
        }
        <Row className="p-3"  style={{ background: COLORS.grey, borderRadius: RADIUS.medium }}>
          <Col lg="12" className="d-flex align-items-center gap-3">
            <div
              className="d-flex align-items-center justify-content-between p-3 gap-2 rounded-3 w-25 position-relative"
              style={{ background: COLORS.grey3 }}
            >
              <Image src={ICONS.camera}/>
              <span className="text-black" style={{ fontSize: SIZES.large }}>{equipments?.selectedEquipment?.shortName || ""}</span>
              <div 
                className="position-absolute z-3"
                style={{ top: "100%", background: COLORS.grey3, right: 0, left: 0, marginTop: 5 }}
              >
                {
                  isShowDropdownListEquipment && equipments.list.map((equipment, index) => {
                    return(
                      <Fragment key={index}>
                        <StyledDropdownList 
                          className="d-flex align-items-center justify-content-between px-3 py-2 gap-2 w-100"
                          onClick={() => onHandleSelectEquipment(equipment)}
                        >
                          <Image src={ICONS.camera}/>
                          <span className="text-black" style={{ fontSize: SIZES.large }}>{equipment?.shortName || ""}</span>
                        </StyledDropdownList>
                      </Fragment>
                    )
                  })
                }
              </div>
            </div>
            <div 
              style={{ 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center", 
                borderRadius: 100, 
                background: "#45491F", 
                height: 26, 
                width: 26,
              }}
              onClick={onHandleShowSelecOption}
            >
              
              <div 
                style={{ border: "none", borderRadius: 100, boxShadow: "none", cursor: "pointer" }}
              >
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.04419 9.22639C7.04419 9.22639 7.08848 9.22639 7.11063 9.22639C7.13277 9.22639 7.15492 9.21162 7.17707 9.20424C7.20659 9.19686 7.23612 9.18209 7.25827 9.17471C7.28042 9.16733 7.29518 9.15257 7.31733 9.14518C7.32471 9.14518 7.33209 9.14518 7.33947 9.13042C7.35424 9.12304 7.35424 9.10827 7.369 9.10089C7.39115 9.07874 7.41329 9.0566 7.43544 9.03445C7.44282 9.01969 7.45759 9.01969 7.46497 9.00492L13.1935 1.19465C13.3854 0.928894 13.3264 0.56717 13.068 0.367853C12.8022 0.175918 12.4405 0.234975 12.2412 0.493349L7.58308 6.84935L6.99251 7.67614L6.40194 6.84935L1.74383 0.493349C1.5519 0.227593 1.18279 0.175918 0.917036 0.367853C0.65128 0.559788 0.599606 0.928894 0.79154 1.19465L6.52006 9.00492C6.52006 9.00492 6.54221 9.01969 6.54959 9.03445C6.57173 9.0566 6.59388 9.07874 6.61603 9.10089C6.63079 9.10827 6.63079 9.12304 6.64555 9.13042C6.65294 9.13042 6.66032 9.13042 6.6677 9.14518C6.68985 9.15995 6.70461 9.16733 6.72676 9.17471C6.75629 9.18948 6.77843 9.19686 6.80796 9.20424C6.83011 9.21162 6.85225 9.219 6.8744 9.22639C6.89655 9.22639 6.91869 9.22639 6.94084 9.22639C6.9556 9.22639 6.97037 9.23377 6.98513 9.23377C6.9999 9.23377 7.01466 9.22639 7.02942 9.22639L7.04419 9.22639Z" fill="white"/>
                </svg>
              </div>
            </div>
            <div style={{ borderRight: `1px solid ${COLORS.grey7}`, height: "100%" }}/>
              <Row className="g-3 w-50">
                <Col lg="12" className="position-relative d-flex align-items-center gap-3">
                  <Button className="w-100 bg-white text-black border-0 p-3 rounded-pill">
                    {t(selectedDate?.split(" ")[0])}
                  </Button>
                  <Button className="w-100 bg-white text-black border-0 p-3 rounded-pill">
                    {t(selectedDate?.split(" ")[1])}
                  </Button>
                  <Button className="w-100 bg-white text-black border-0 p-3 rounded-pill">
                    {t(selectedDate?.split(" ")[2])}
                  </Button>
                  <Button 
                    className="bg-transparent text-black border-0 p-3 rounded-pill position-relative"
                    onClick={() => setIsShowCalendarFilter((prevState) => !prevState)}
                  >
                    <Image src={ICONS.menu}/>
                    
                  </Button>
                  {
                      isShowCalendarFilter &&
                      <div className="position-absolute z-1" style={{ left: "45%", top: "calc(100% + 10px)" }}>
                        <DatePicker
                          selected={new Date()}
                          onChange={(date) => onHandleFilterDate(date)}
                          dateFormat="yyyy-MM-dd"
                          placeholderText="Select a date"
                          inline
                        />
                      </div>
                    }
                </Col>
              </Row>
            {/* <Button className=" bg-white text-black border-0 p-3 rounded-pill" style={{ width: 150 }}>
              {t("Day")}
            </Button>
            <Button className=" bg-white text-black border-0 p-3 rounded-pill" style={{ width: 200 }}>
              {t("December")}
            </Button>
            <Button className=" bg-white text-black border-0 p-3 rounded-pill" style={{ width: 200 }}>
              2023
            </Button>
            <Button 
              className="bg-transparent text-black border-0 p-3 rounded-pill"
            >
              <Image src={ICONS.menu}/>
            </Button> */}
          </Col>
        </Row>
      </Col>
      <Col xxl="6" xl="12">
        <Row className="px-5 py-4 position-relative" style={{ background: "#F2EDE8", borderRadius: "20px" }}>
          <Loading isLoading={devices.isLoading}/>
          {
            devices?.list.length === 0 && <span>{t("No Data Available")}</span>
          }
          <Carousel 
            activeIndex={selectedIndexCarousel} 
            onSelect={handleSelectNextPrevCarousel} 
            interval={null}
          >
            {
              devices?.list?.map(device => {
                return (
                  <Carousel.Item key={device.equipmentId}>
                    <div style={{ width: "100%", height: 420 }}>
                      <Image src={device?.capturedPhoto} text="First slide" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    </div>
                  </Carousel.Item>
                )
              })
            }
          </Carousel>
          <Stack className="mt-4" gap={3}>
          {
            equipments.selectedDevice &&
            <Fragment>
              <Stack gap={3} direction="horizontal">
                <Stack>
                  <span style={{ fontSize: SIZES.small, color: COLORS.grey7 }}>{t("Taken Date")}</span>
                  <span style={{ fontSize: "22px" }}>{moment(equipments.selectedDevice.capturedAt).format('DD/MM/YYYY')}</span>
                </Stack>
                <Stack>
                  <span style={{ fontSize: SIZES.small, color: COLORS.grey7 }}>{t("Taken Time")}</span>
                  <span style={{ fontSize: "22px" }}>{moment(equipments.selectedDevice.capturedAt).format('HH:mm:ss')}</span>
                </Stack>
              </Stack>
              <Stack  gap={3}>
                <Stack>
                  <span style={{ fontSize: SIZES.small, color: COLORS.grey7 }}>{t("Caught By")}</span>
                  <span style={{ fontSize: "22px" }}>{equipments.selectedDevice.capturedBy}</span>
                </Stack>
                <Stack>
                  <span style={{ fontSize: SIZES.small, color: COLORS.grey7 }}>{t("Next Scheduled Image Capture")}</span>
                  <span style={{ fontSize: "22px" }}>{moment(equipments.selectedDevice.capturedAt).add(2, 'days').format('DD/MM/YYYY')}</span>
                </Stack>
              </Stack>
            </Fragment>
          }
          </Stack>
        </Row>
      </Col>
      <Col xxl="6" xl="12">
        <Row className="px-3 py-4 position-relative" style={{ background: "#F2EDE8", borderRadius: RADIUS.medium }}>
          <Loading isLoading={devices.isLoading || equipments.isLoading}/>
          {
            devices?.list.length === 0 ? <span>{t("No Data Available")}</span> :
            <>
              <h3>{t("Identified Insects")}</h3>
              <Formik
                enableReinitialize={true}
                onSubmit={handleSubmit}
                initialValues={initialFormValues}
              >
                {
                  (props) => {
                    return (
                      <Form className="d-flex flex-column gap-3">
                        <div className="d-flex align-items-center gap-3">
                          <div className="border-1 border-bottom d-flex w-100" />
                          <div 
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              props.setFieldValue("identifiedInsects", [...props.values.identifiedInsects, { type: "", quantity: ""}])
                            }}
                          >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M-0.000186682 20.0001C-0.00018655 31.0458 8.95416 40.0002 19.9999 40.0002C31.0457 40.0002 40 31.0458 40 20.0001C40 8.95435 31.0457 1.0678e-07 19.9999 2.38499e-07C8.95416 3.70218e-07 -0.000186813 8.95435 -0.000186682 20.0001Z" fill="#45491F"/>
                              <path d="M19 11H21V29H19V11Z" fill="#D9D9D9"/>
                              <path d="M11 21L11 19L29 19V21L11 21Z" fill="#D9D9D9"/>
                            </svg>
                          </div>
                        </div>
                        <Stack gap={3} className="px-2 py-4">
                          {
                            props.values.identifiedInsects?.map((item, index) => {
                              return (
                                <Fragment key={index}>
                                  <div 
                                    onClick={() => {
                                      const newIdentifiedInsects = props.values.identifiedInsects.filter((item, i) => index !== i);
                                      props.setFieldValue("identifiedInsects", newIdentifiedInsects)
                                    }}
                                    style={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
                                  >
                                    <svg  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10ZM6.97 6.97C7.11063 6.82955 7.30125 6.75066 7.5 6.75066C7.69875 6.75066 7.88937 6.82955 8.03 6.97L10 8.94L11.97 6.97C12.1122 6.83752 12.3002 6.7654 12.4945 6.76882C12.6888 6.77225 12.8742 6.85097 13.0116 6.98838C13.149 7.12579 13.2277 7.31118 13.2312 7.50548C13.2346 7.69978 13.1625 7.88783 13.03 8.03L11.06 10L13.03 11.97C13.1625 12.1122 13.2346 12.3002 13.2312 12.4945C13.2277 12.6888 13.149 12.8742 13.0116 13.0116C12.8742 13.149 12.6888 13.2277 12.4945 13.2312C12.3002 13.2346 12.1122 13.1625 11.97 13.03L10 11.06L8.03 13.03C7.88783 13.1625 7.69978 13.2346 7.50548 13.2312C7.31118 13.2277 7.12579 13.149 6.98838 13.0116C6.85097 12.8742 6.77225 12.6888 6.76882 12.4945C6.7654 12.3002 6.83752 12.1122 6.97 11.97L8.94 10L6.97 8.03C6.82955 7.88937 6.75066 7.69875 6.75066 7.5C6.75066 7.30125 6.82955 7.11063 6.97 6.97Z" fill="#2E2E2E"/>
                                    </svg>
                                  </div>
                                  <InputField 
                                    controlId="formType"
                                    type="text"
                                    required={true}
                                    placeholder={t("Insect Type")}
                                    value={props.values.shortName}
                                    name={`identifiedInsects[${index}].type`}
                                    className="border-bottom-0 w-100 bg-white text-black border-0 p-3 rounded-pill"
                                    style={{ fontSize: 25, textAlign: "center" }}
                                    {...props} 
                                  />
                                  <InputField 
                                    controlId="formType"
                                    type="number"
                                    required={true}
                                    placeholder={t("Insect Quantity")}
                                    value={props.values.shortName}
                                    name={`identifiedInsects[${index}].quantity`}
                                    className="border-bottom-0 w-100 bg-white text-black border-0 p-3 rounded-pill"
                                    style={{ fontSize: 25, textAlign: "center" }}
                                    {...props} 
                                  />
                                </Fragment>
                              )
                            })
                          }
                        </Stack>
                        <h3>
                          {t("Action")}
                          <hr/>
                        </h3>
                        
                          {
                            ACTIONS.map((action) => {
                              return (
                                <Fragment key={action.value}>
                                  <FormGroup>
                                    <StyledFormCheck checked={props.values.action}>
                                      <FormCheckLabel>{t(action.name)}</FormCheckLabel>
                                      <FormCheckInput 
                                        type="radio" 
                                        name="action" 
                                        className="p-2" 
                                        value={t(action.value)} 
                                        checked={props.values.action === action.value}  
                                        onChange={props.handleChange}
                                      />
                                    </StyledFormCheck>
                                  </FormGroup>
                                </Fragment>
                              )
                            })
                          }
                        
                        <FormGroup controlId="formDate" className="m-auto w-75">
                          <Row>
                            {['month1', 'month2', 'day1', 'day2', 'year1', 'year2', 'year3', 'year4'].map((field, index) => (
                              <React.Fragment key={field}>
                                {index === 2 || index === 4 ? (
                                  <Col xs="auto" className="d-flex align-items-center">/</Col>
                                ) : null}
                                <Col className="px-1">
                                  <FormControl
                                    type="text"
                                    name={field}
                                    value={props.values.date[field]}
                                    onChange={() => handleChange(props, field)}
                                    onKeyDown={(e) => handleKeyDown(e, props, field)}
                                    placeholder={field[0].toUpperCase()}
                                    maxLength={1}
                                    ref={refs[field]}
                                    style={{ textAlign: 'center' }}
                                  />
                                </Col>
                              </React.Fragment>
                            ))}
                          </Row>
                        </FormGroup>
                        <div className="w-full d-flex justify-content-center mt-3">
                          <StyledButton className="w-100" variant={COLORS.dark} iconLeft={t("Send Information to Farmer")} type="submit">
                            { " " }
                          </StyledButton>
                        </div>
                      </Form>
                    )              }
                }
              </Formik>
            </>
          }
        </Row>
      </Col>
    </Row>
  );
}

export default InsectCountingPage;

const StyledButton = styled(Button)`
`

const StyledFormCheck = styled(FormCheck)`
  .form-check-label {
    font-size: 20px;
  }
  .form-check-input {
    outline: none;
    box-shadow: none;
    /* height: 50px;
    width: 50px; */
    /* background-color: #fff; */
  }

  .form-check-input:checked {
    background-image: url(${ICONS.radioIconCircle});
    background-color: #fff;
    border-color: #fff;
    /* background: #fff ;
    border: 1px solid #000; */
  }
`

const StyledDropdownList = styled.div`
  cursor: pointer;
  border-bottom: 1px solid gray;
  /* :hover {
    background-color: red;
  } */
`