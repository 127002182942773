import { createSlice } from "@reduxjs/toolkit";
import {
  registerFarmerAccountUsingEmail,
} from "./actions";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  success: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    forceClearAllUserState: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerFarmerAccountUsingEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerFarmerAccountUsingEmail.fulfilled, (state, action) => {
        state.success = true;
      })
      .addCase(registerFarmerAccountUsingEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || "An error occurred";
      })
  },
});

export const { 
  forceClearAllUserState
} = userSlice.actions;

export default userSlice.reducer;
