import { Navigate } from "react-router-dom";
import Loadable from "../hoc/Loadable";
import { lazy } from "react";
import ProtectedLayout from "../components/template/ProtectedLayout";
import MapOverViewPage from "../pages/Admin/MapOverView";
import EquipmentInformationPage from "../pages/Admin/EquipmentInformation";
import InsectCountingPage from "../pages/Admin/InsectCounting";
import LatestNewsPage from "../pages/Admin/LatestNews";
import LiveChatPage from "../pages/Admin/LiveChat";
import SettingsPage from "../pages/Admin/Settings";
import PublicLayout from "components/template/PublicLayout";
import RegisterEquipmentPage from "pages/Admin/RegisterEquipment";
import AuthMiddlewareRoutes from "./authMiddlewareRoutes";
import FarmerAccountsPage from "pages/Admin/FarmerAccounts";

// const MerchantProfile = Loadable(lazy(() => import("../pages/merchant/Profile")))

// MERCHANT PAGE END

const SigninPage = Loadable(lazy(() => import("../pages/Signin")));
const EditProfilePage = Loadable(lazy(() => import("../pages/EditProfile")));
const ForgotPasswordStep1Page = Loadable(lazy(() => import("../pages/ForgotPasswordStep1")));
const ForgotPasswordStep2Page = Loadable(lazy(() => import("../pages/ForgotPasswordStep2")));
const OtpPage = Loadable(lazy(() => import("../pages/Otp")));
const SignupPage = Loadable(lazy(() => import("../pages/Signup")));

const authProtectedRoutes = [
  {
    path: "admin",
    element: (
      <AuthMiddlewareRoutes role="admin">
        <ProtectedLayout />
      </AuthMiddlewareRoutes>
    ),
    children: [
      {
        path: "map-overview",
        children: [
          {
            path: "",
            element: <MapOverViewPage />,
          },
        ],
      },
      {
        path: "register-equipment",
        children: [
          {
            path: "",
            element: <RegisterEquipmentPage />,
          },
        ],
      },
      {
        path: "equipment-information",
        children: [
          {
            path: "",
            element: <EquipmentInformationPage />,
          },
        ],
      },
      {
        path: "insect-counting",
        children: [
          {
            path: "",
            element: <InsectCountingPage />,
          },
        ],
      },
      {
        path: "latest-news",
        children: [
          {
            path: "",
            element: <LatestNewsPage />,
          },
        ],
      },
      {
        path: "live-chat",
        children: [
          {
            path: "",
            element: <LiveChatPage />,
          },
        ],
      },
      {
        path: "settings",
        children: [
          {
            path: "",
            element: <SettingsPage />,
          },
        ],
      },
      {
        path: "farmer-accounts",
        children: [
          {
            path: "",
            element: <FarmerAccountsPage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    children: [
      {
        path: "", // equivalent to 👉 / to fix react-router bug
        element: <Navigate to="/signin" />,
      },
    ],
  },
];

const publicRoutes = [
  {
    path: "signin",
    element: (
      <PublicLayout/>
    ),
    children: [
      {
        path: "",
        element: <SigninPage />,
      },
    ],
  },
  {
    path: "signup",
    element: (
      <PublicLayout/>
    ),
    children: [
      {
        path: "",
        element: <SignupPage />,
      },
    ],
  },
  {
    path: "edit-profile",
    element: (
      <PublicLayout/>
    ),
    children: [
      {
        path: "",
        element: <EditProfilePage />,
      },
    ],
  },
  {
    path: "forgot-password-step-1",
    element: (
      <PublicLayout/>
    ),
    children: [
      {
        path: "",
        element: <ForgotPasswordStep1Page />,
      },
    ],
  },
  {
    path: "forgot-password-step-2",
    element: (
      <PublicLayout/>
    ),
    children: [
      {
        path: "",
        element: <ForgotPasswordStep2Page />,
      },
    ],
  },
  {
    path: "otp",
    element: (
      <PublicLayout/>
    ),
    children: [
      {
        path: "",
        element: <OtpPage />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <PublicLayout/>
    ),
    children: [
      {
        path: "unauthorized",
        element: <div>
          Unauthorized Page
        </div>,
      },
      {
        path: "*",
        element: <div>
        404 Page
      </div>,
      }
    ],
  },
];

export { authProtectedRoutes, publicRoutes };