import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/apiService";
import { setCookie } from "helpers/cookies";
import { resetAuthState } from "./reducer";

export const loginUsingEmail = createAsyncThunk(
  "auth/loginUsingEmail",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.post(`/signin?type=web`, payload);
      const data = response.data?.d
      // const date = new Date();
      // date.setTime(date.getTime() + (10 * 1000));\
      if (data.role === "admin") {
        setCookie(
          "signinUserData",
          {
            role: data.role ? data.role : "",
            token: data?.token,
            isFinishedProfile: data.isFinishedProfile || true,
          },
          // { expires:date }
        ); // this also detects by middleware.js but only on first page load or each visit of any specific page
      } else {
        dispatch(resetAuthState({ error: "Error" , message: `${data.role} role is not allowed`}))
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error logging in");
    }
  }
);

export const registerUsingEmail = createAsyncThunk(
  "auth/registerUsingEmail",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.post(`/signup`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error register");
    }
  }
);

export const getUserMe = createAsyncThunk(
  "auth/getUserMe",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/users/me`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error get user me");
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.put(`/users/me`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error get user me");
    }
  }
);

export const forgotPasswordStep1 = createAsyncThunk(
  "auth/forgotPasswordStep1",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.post(`/signin/forgot-password-step-1`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error forgot password step 1");
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.post(`/signin/verify-otp-code`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error verify Otp");
    }
  }
);

export const forgotPasswordStep2 = createAsyncThunk(
  "auth/forgotPasswordStep2",
  async ({userId, payload}, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.put(`/signin/change-password/${userId}`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error forgot password step 2");
    }
  }
);