import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

const SuccessModal = ({ isShow, onCancel, onOk, title, description, centered = true, isClosesable = true }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={isShow} onHide={() => isClosesable && onCancel} centered={centered}>
        <Modal.Header closeButton={isClosesable} style={{ background: "#C7BFB3" }}>
          <Modal.Title>{title ?? ""}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#C7BFB3" }}>{description}</Modal.Body>
        <Modal.Footer style={{ background: "#C7BFB3" }}>
          <Button variant="light" style={{ background: "#464A1F", border: "none", color: "#fff" }} onClick={onOk}>
            {t("Okay")}
          </Button>
          {
            isClosesable &&
            <Button variant="light" onClick={onCancel}>
              {t("Close")}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuccessModal;