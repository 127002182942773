import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPasswordStep1,
  forgotPasswordStep2,
  getUserMe,
  loginUsingEmail,
  registerUsingEmail,
  updateUserProfile,
  verifyOtp,
} from "./actions";
import { getCookie, removeCookie, updateCookieData } from "helpers/cookies";

const initialState = {
  userInfo: null,
  token: getCookie("signinUserData")?.token
  ? getCookie("signinUserData")?.token
  : null,
  isFinishedProfile: getCookie("signinUserData")?.isFinishedProfile
  ? getCookie("signinUserData")?.isFinishedProfile
  : false,
  isAuthenticated: getCookie("signinUserData")?.token
  ? true
  : false,
  role: getCookie("signinUserData")?.role
  ? getCookie("signinUserData")?.role
  : null,
  isLoading: false,
  error: null,
  success: null,
  userId: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    forceClearAllAuthState: () => {
      return initialState
    },
    finishProfile: (state, action) => {
      state.isFinishedProfile = true;
      updateCookieData("signinUserData", { isFinishedProfile: true })
    },
    cleartAuthState: (state, action) => {
      state.isLoading = false;
      state.userInfo = null;
      state.isAuthenticated = false;
      state.isFinishedProfile = false;
      state.token = null;
      state.error = action.payload?.errorMessage ? action.payload.errorMessage : null;
      state.success = null;
      removeCookie("signinUserData")
    },
    resetAuthState: (state, action) => {
      state.isLoading = false;
      state.success = action.payload.success || "";
      state.error = action.payload.error || "";
      state.message = action.payload?.message ? action.payload.message : null;
    },
    cookiesigninUserDataExpiredState: (state, action) => {
      state.userInfo = null;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.token = null;
      state.error = "Session expired please log in again";
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUsingEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUsingEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.d.role === "admin") {
          state.isFinishedProfile = getCookie("signinUserData")?.isFinishedProfile
          ? getCookie("signinUserData")?.isFinishedProfile
          : false;
          state.role = getCookie("signinUserData")?.role
          ? getCookie("signinUserData")?.role
          : "";
          state.isAuthenticated = true;
          state.success = true;
        }
      })
      .addCase(loginUsingEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || "An error occurred";
        state.message = action.payload.m || "An error occurred";
      })

      .addCase(registerUsingEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerUsingEmail.fulfilled, (state, action) => {
        state.success = true;
      })
      .addCase(registerUsingEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || "An error occurred";
      })

      .addCase(getUserMe.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUserMe.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = "Successfuly get user me";
        state.userInfo = action.payload.d;
      })
      .addCase(getUserMe.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || "An error occurred";
      })

      .addCase(updateUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = "Successfuly get user me";
        state.userInfo = action.payload.d;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || "An error occurred";
      })

      .addCase(forgotPasswordStep1.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(forgotPasswordStep1.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = "Successfuly forgot password step 1";
      })
      .addCase(forgotPasswordStep1.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || "An error occurred";
      })

      .addCase(forgotPasswordStep2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(forgotPasswordStep2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = "Successfuly forgot password step 2";
        state.userInfo = action.payload.d;
      })
      .addCase(forgotPasswordStep2.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload.m || "An error occurred";
      })

      .addCase(verifyOtp.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = "Success";
        state.userId = action.payload.d.user.id;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        console.log(action.payload);
        state.error = action.payload?.m || "An error occurred";
      })

  },
});

export const { 
  cleartAuthState, 
  cookiesigninUserDataExpiredState, 
  finishProfile,
  resetAuthState,
  forceClearAllAuthState
} = authSlice.actions;

export default authSlice.reducer;
