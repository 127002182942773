import Button from 'components/UI/Button'
import QRCode from 'qrcode.react'
import React, { Fragment, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ScanQrModal = ({ isShow, onCancel, onOk, value }) => {
  const qrRef = useRef();
  const { t } = useTranslation();

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector('canvas');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Fragment>
      <Modal show={isShow} onHide={onCancel} centered={true}>
        <Modal.Header closeButton style={{ background: "#C7BFB3" }}>
          <Modal.Title>{t("Scan QR")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#C7BFB3" }}>
          <div ref={qrRef} className="bg-white p-2 m-auto d-flex justify-content-center">
            <QRCode
              level="H"
              id="qr-gen"
              size={300}
              bgColor="#ffff"
              includeMargin={true}
              value={value}
              renderAs="canvas"
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "#C7BFB3" }}>
          <Button 
            variant="light" 
            style={{ background: "#464A1F", border: "none", color: "#fff" }} 
            onClick={() => downloadQRCode()}
          >
            {t("Download QR Code")}
          </Button>
          <Button variant="dark" onClick={onCancel}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
      
    </Fragment>
  )
}

export default ScanQrModal