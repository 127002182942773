import debounceCallback from "helpers/debounceCallback";
import { useEffect, useState } from "react";

export const useResponsiveToggle = (breakpoint, delay = 0) => {
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    const handleResize = debounceCallback(() => {
      if (window.innerWidth >= breakpoint) {
        setToggle(true);
      } else {
        setToggle(false);
      }
    }, delay);

    // Set initial state based on current window size
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint, delay]);

  return [toggle, setToggle];
};