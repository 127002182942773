import { ICONS } from 'assets/images';
import MapContainer from 'components/Layout/Maps';
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import { COLORS, RADIUS, SIZES } from 'constant/themes';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Accordion, Col, Image, Row, Stack } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'store';
import { useSelector } from 'store';
import { getDevicesByEquipmentId, getRegisterEquipments } from 'store/equipments/actions';
import { resetDeviceList, setCoordinates, setSelectedDevice, setSelectedEquipment } from 'store/equipments/reducer';
import styled from 'styled-components';
import ScanQrModal from './ScanQrModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EquipmentInformationPage = () => {
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState("0");
  const [isShowQrModal, setIsShowQrModal] = useState(false);
  const [isShowCalendarFilter, setIsShowCalendarFilter] = useState(false);
  const [selectedDate, setSelectedDate] = useState("Day Month Year")

  const navigate = useNavigate();
  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const { equipments, devices } = useSelector(
    (state) => state.equipments
  );

  const coordinates = equipments.coordinates;

  // const [selectedItemDevice, setSelectedItemDevice] = useState(null);
  
  const dispatch = useDispatch();

  const getEquipments = async () => {
    const result = await dispatch(getRegisterEquipments(`?additionalInfo=1`));
    const data = result.payload.d
    dispatch(setSelectedEquipment(equipments.selectedEquipment || data[0]));
    dispatch(setCoordinates(equipments.selectedEquipment || data[0]));
    // setSelectedItemDevice({ ...equipments.selectedDevice, selectedDeviceIndex: 0 } || data[0]?.equipments[0]);
    dispatch(setSelectedDevice({ ...equipments.selectedDevice, selectedDeviceIndex: 0 } || data[0]?.equipments[0] || null));
  }

  useEffect(() => {
    getEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // console.log(selectedItemDevice)
  const getDevices = () => {
    dispatch(getDevicesByEquipmentId({ deviceId: equipments.selectedDevice?.deviceId || equipments.selectedEquipment?.id}));
    // setSelectedItemDevice({ ...equipments.selectedDevice, selectedDeviceIndex: 0 } || data[0]?.equipments[0]);
  }
  
  console.log(equipments.selectedEquipment?.id);

  useEffect(() => {
    if (equipments.selectedDevice?.deviceId || equipments.selectedEquipment?.id) {
      getDevices();
    } else {
      dispatch(setSelectedDevice(null));
      // setSelectedItemDevice(null);
      dispatch(resetDeviceList());
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipments.selectedDevice?.deviceId, equipments.selectedEquipment?.id])

  const onHandleSelectDevice = (selectedDevice, selectedDeviceIndex) => {
    dispatch(setSelectedDevice({ ...devices.list[Number(selectedDeviceIndex)], selectedDeviceIndex} || equipments.selectedDevice ));
    navigate("/admin/insect-counting");
  }

  const onHandleFilterDate = (date) => {
    setIsShowCalendarFilter((prevState) => !prevState)
    // dispatch(getRegisterEquipments(`?additionalInfo=1&capturedDate=${moment(date).format('YYYY-MM-DD')}`));
    dispatch(getDevicesByEquipmentId({ deviceId: equipments.selectedDevice?.deviceId, query: date ? `&capturedDate=${moment(date).format('YYYY-MM-DD')}` : "" }));
    setSelectedDate(moment(date).format('DD MM YYYY'));
  };

  return (
    <Row className="g-5" >
      <ScanQrModal
        isShow={isShowQrModal}
        onCancel={() => setIsShowQrModal(false)}
        value={equipments?.selectedEquipment?.id}
      />
      <Col xl="7" lg="12" className="pb-3" style={{ borderBottom: `2px solid ${COLORS.grey4}`}}>
        <Row className="position-relative" style={{ borderRadius: "20px", height: 720 }}>
          <Loading isLoading={devices.isLoading} text="Loading Map..."/>
          <MapContainer coordinates={coordinates} isCustomMarker={true}/>
        </Row>
        {/* <div className="image-container">
          <Image src={IMAGES.genericMap} fluid />
        </div> */}
        <h1 className="mt-4" style={{ color: "#464A1F", fontSize: SIZES.xxl }}>{t("Latest Pictures")}</h1>
        <hr className="text-dark" />

        {
          devices?.list[0] &&
          <Row className="position-relative">
            <Loading isLoading={equipments?.isLoading}  text="Loading Latest News..."/>
            <Col lg="6">
              <div className="image-container" 
                style={{
                  cursor: "pointer",
                  height: "380px"
                }}
                onClick={() => {
                  navigate("/admin/insect-counting");
                }}
              >
                <Image src={devices?.list[0]?.capturedPhoto} className="img-fluid w-100 h-100" style={{ objectFit: "cover" }}/>
              </div>
            </Col>
            <Col lg="6" className="d-flex flex-column justify-content-between">
              <Row>
                <Col lg="12">
                  <Stack gap={3}>
                    <Stack gap={3} direction="horizontal">
                      <Stack>
                        <span style={{ fontSize: SIZES.small, color: COLORS.grey7 }}>{t("Taken Date")}</span>
                        <span style={{ fontSize: "22px" }}>{moment(devices?.list[0]?.capturedAt).format('DD/MM/YYYY')}</span>
                      </Stack>
                      <Stack>
                        <span style={{ fontSize: SIZES.small, color: COLORS.grey7 }}>{t("Taken Time")}</span>
                        <span style={{ fontSize: "22px" }}>{moment(devices?.list[0]?.capturedAt).format('HH:mm:ss')}</span>
                      </Stack>
                    </Stack>
                    <Stack  gap={3}>
                      <Stack>
                        <span style={{ fontSize: SIZES.small, color: COLORS.grey7 }}>{t("Caught By")}</span>
                        <span style={{ fontSize: "22px" }}>{devices?.list[0]?.capturedBy}</span>
                      </Stack>
                      <Stack>
                        <span style={{ fontSize: SIZES.small, color: COLORS.grey7 }}>{t("Next Scheduled Image Capture")}</span>
                        <span style={{ fontSize: "22px" }}>{moment(devices?.list[0]?.capturedAt).add(2, 'days').format('DD/MM/YYYY')}</span>
                      </Stack>
                    </Stack>
                  </Stack>
                </Col>
              </Row>
              {/* <Button className="px-3 py-2 w-75" variant={COLORS.dark}
                iconLeft={t("Send Reminder")}
                iconRight={<Image src={ICONS.bell1}/>}
              /> */}
            </Col>
          </Row>
        }
      </Col>
      <Col xl="5" lg="12" >
        <Row className="p-3 position-relative" style={{ background: COLORS.tertiary, borderRadius: RADIUS.smallMeduim }} >
          <Loading isLoading={equipments?.isLoading} text="Loading Devices..."/>
          <StyledAccordion 
            defaultActiveKey={activeKey} 
            onSelect={(index) => handleToggle(index)}
          >
            {
              [equipments.selectedDevice]?.map((item, index) => {
                return (
                  <StyledAccordionItem eventKey={index.toString()} key={index}>
                    <Accordion.Header>
                      <span className="flex-grow-1">
                        {/* {t("More Images")} */}
                      </span>
                      <Button
                      variant="#C7BFB3" 
                        className="me-3 text-dark"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsShowQrModal(true);
                        }}
                      >
                        {t("QR Code")}
                      </Button>
                      <div
                        className="d-flex align-items-center justify-content-between p-3 gap-2 rounded-3 me-3"
                        style={{ background: COLORS.grey3 }}
                      >
                        <Image src={ICONS.camera}/>
                        <span className="text-black" style={{ fontSize: SIZES.large }}>{equipments?.selectedEquipment?.shortName}</span>
                        {/* {listItem.image} */}
                      </div>
                      {
                        activeKey === index.toString() ? 
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 100, background: "#45491F", height: 26, width: 26 }}>
                          <div style={{ border: "none", borderRadius: 100, boxShadow: "none" }}>
                            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.04419 9.22639C7.04419 9.22639 7.08848 9.22639 7.11063 9.22639C7.13277 9.22639 7.15492 9.21162 7.17707 9.20424C7.20659 9.19686 7.23612 9.18209 7.25827 9.17471C7.28042 9.16733 7.29518 9.15257 7.31733 9.14518C7.32471 9.14518 7.33209 9.14518 7.33947 9.13042C7.35424 9.12304 7.35424 9.10827 7.369 9.10089C7.39115 9.07874 7.41329 9.0566 7.43544 9.03445C7.44282 9.01969 7.45759 9.01969 7.46497 9.00492L13.1935 1.19465C13.3854 0.928894 13.3264 0.56717 13.068 0.367853C12.8022 0.175918 12.4405 0.234975 12.2412 0.493349L7.58308 6.84935L6.99251 7.67614L6.40194 6.84935L1.74383 0.493349C1.5519 0.227593 1.18279 0.175918 0.917036 0.367853C0.65128 0.559788 0.599606 0.928894 0.79154 1.19465L6.52006 9.00492C6.52006 9.00492 6.54221 9.01969 6.54959 9.03445C6.57173 9.0566 6.59388 9.07874 6.61603 9.10089C6.63079 9.10827 6.63079 9.12304 6.64555 9.13042C6.65294 9.13042 6.66032 9.13042 6.6677 9.14518C6.68985 9.15995 6.70461 9.16733 6.72676 9.17471C6.75629 9.18948 6.77843 9.19686 6.80796 9.20424C6.83011 9.21162 6.85225 9.219 6.8744 9.22639C6.89655 9.22639 6.91869 9.22639 6.94084 9.22639C6.9556 9.22639 6.97037 9.23377 6.98513 9.23377C6.9999 9.23377 7.01466 9.22639 7.02942 9.22639L7.04419 9.22639Z" fill="white"/>
                            </svg>
                          </div>
                        </div> : 
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 100, background: "#45491F", height: 26, width: 26 }}>
                          <div style={{ border: "none", borderRadius: 100, boxShadow: "none" }}>
                            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.95581 0.773583C6.95581 0.773583 6.91152 0.773583 6.88937 0.773583C6.86723 0.773583 6.84508 0.788348 6.82293 0.795729C6.79341 0.803112 6.76388 0.817876 6.74173 0.825257C6.71958 0.832639 6.70482 0.847404 6.68267 0.854787C6.67529 0.854787 6.66791 0.854787 6.66053 0.869551C6.64576 0.876933 6.64576 0.891697 6.631 0.899079C6.60885 0.921225 6.58671 0.943372 6.56456 0.965518C6.55718 0.980282 6.54241 0.980283 6.53503 0.995047L0.806513 8.80532C0.614579 9.07108 0.673636 9.4328 0.932009 9.63212C1.19777 9.82405 1.55949 9.76499 1.75881 9.50662L6.41692 3.15062L7.00749 2.32383L7.59806 3.15062L12.2562 9.50662C12.4481 9.77238 12.8172 9.82405 13.083 9.63212C13.3487 9.44018 13.4004 9.07108 13.2085 8.80532L7.47994 0.995047C7.47994 0.995047 7.45779 0.980282 7.45041 0.965518C7.42827 0.943371 7.40612 0.921225 7.38397 0.899079C7.36921 0.891697 7.36921 0.876933 7.35445 0.869551C7.34706 0.869551 7.33968 0.86955 7.3323 0.854787C7.31015 0.840022 7.29539 0.832639 7.27324 0.825257C7.24371 0.810493 7.22157 0.803112 7.19204 0.795729C7.16989 0.788348 7.14775 0.780965 7.1256 0.773583C7.10345 0.773583 7.08131 0.773583 7.05916 0.773583C7.0444 0.773583 7.02963 0.766201 7.01487 0.766201C7.0001 0.766201 6.98534 0.773583 6.97057 0.773583L6.95581 0.773583Z" fill="white"/>
                            </svg>
                          </div>
                        </div>
                      }
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row >
                        <Col lg="12">
                          <Row className="g-3">
                            <Col lg="12" className="position-relative d-flex align-items-center gap-3">
                              {/* <Formik
                                // enableReinitialize={true}
                                // onSubmit={handleSubmit}
                                // initialValues={initialFormValues}
                              >
                                {
                                  (props) => {
                                    return (
                                      <Form className="">
                                        <InputField 
                                          controlId="formType"
                                          type="text"
                                          required={true}
                                          placeholder={t("Day")}
                                          // value={props.values.shortName}
                                          name={`identifiedInsects[${index}].type`}
                                          className="border-bottom-0 w-100 bg-white text-black border-0 p-3 rounded-pill"
                                          style={{ fontSize: 25, textAlign: "center" }}
                                          {...props} 
                                        />
                                      </Form>
                                  )}
                                }
                              </Formik> */}
                              <Button className="w-100 bg-white text-black border-0 p-3 rounded-pill">
                                {t(selectedDate?.split(" ")[0])}
                              </Button>
                              <Button className="w-100 bg-white text-black border-0 p-3 rounded-pill">
                                {t(selectedDate?.split(" ")[1])}
                              </Button>
                              <Button className="w-100 bg-white text-black border-0 p-3 rounded-pill">
                                {t(selectedDate?.split(" ")[2])}
                              </Button>
                              <Button 
                                className="bg-transparent text-black border-0 p-3 rounded-pill position-relative"
                                onClick={() => setIsShowCalendarFilter((prevState) => !prevState)}
                              >
                                <Image src={ICONS.menu}/>
                                
                              </Button>
                              {
                                  isShowCalendarFilter &&
                                  <div className="position-absolute z-1" style={{ left: "45%", top: "calc(100% + 10px)" }}>
                                    <DatePicker
                                      selected={new Date()}
                                      onChange={(date) => onHandleFilterDate(date)}
                                      dateFormat="yyyy-MM-dd"
                                      placeholderText="Select a date"
                                      inline
                                    />
                                  </div>
                                }
                            </Col>
                            <Col lg="12" style={{ maxHeight: '70vh', overflowY: 'auto',  overflowX: "hidden"  }}>
                              <Loading isLoading={devices.isLoading}/>
                              {
                                devices?.list.length === 0 && <span>{t("No Data Available")}</span>
                              }
                              {
                                devices?.list?.map((device, deviceIndex) => {
                                  return (
                                    <Row className="g-3" key={deviceIndex}>
                                      <Col lg="12">
                                        <div
                                          onClick={() => onHandleSelectDevice(device, deviceIndex)}
                                          style={{
                                            height: "258px",
                                            cursor: "pointer"
                                          }}
                                        >
                                          <Image src={device?.capturedPhoto} className="w-100 h-100" style={{ objectFit: "cover" }}/>
                                        </div>
                                      </Col>
                                      <Col lg="12">
                                      <Row className="p-3">
                                        <Col lg="4"  style={{ borderRight: `1px solid ${COLORS.grey7}`}}>
                                          <Stack>
                                            <span style={{ fontSize: SIZES.smallLarge, color: COLORS.grey7 }}>{t("Caught By")}</span>
                                            <span style={{ fontSize: SIZES.smallXl }}>{device?.capturedBy}</span>
                                          </Stack>
                                        </Col>
                                        <Col lg="4" style={{ borderRight: `1px solid ${COLORS.grey7}`}}>
                                          <Stack>
                                            <span style={{ fontSize: SIZES.smallLarge, color: COLORS.grey7 }}>{t("Date")}</span>
                                            <span style={{ fontSize: SIZES.smallXl }}>{moment(device?.capturedAt).format('DD/MM/YYYY')}</span>
                                          </Stack>
                                        </Col>
                                        <Col>
                                          <Stack>
                                            <span style={{ fontSize: SIZES.smallLarge, color: COLORS.grey7 }}>{t("Time")}</span>
                                            <span style={{ fontSize: SIZES.smallXl }}>{moment(device?.capturedAt).format('HH:mm:ss')}</span>
                                          </Stack>
                                        </Col>
                                      </Row>
                                      </Col>
                                    </Row>
                                  )
                                })
                              }
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </StyledAccordionItem>
                )
              })
            }
          </StyledAccordion>
        </Row>
      </Col>
    </Row>
  )
}

export default EquipmentInformationPage

const StyledAccordion = styled(Accordion)`
  background-color: none;
  
  /* .accordion-item {
    &:first-of-type {

    }
  } */
  .accordion-item {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .accordion-header {
    border-bottom: 1.5px solid #ADB3A1;

    .accordion-button {
      outline: none !important;
      box-shadow: none !important;
    }

    .accordion-button::after {
      display: none;
      /* color: white;
      background-color: #45491F; */
    }
  }

  .accordion-button {
    background-color: #F2EDE8;
    border:none;

    display: flex;
    justify-content:space-between;
  }

  .accordion-body {
    background-color: #F2EDE8;
    padding: 15px 0px;
    border: none;

  }

  .accordion-collapse {
  }
  
`

const StyledAccordionItem = styled(Accordion.Item)`

  &:last-of-type {
    border-radius: none !important;
  }
`
