import React, { useEffect, useState } from 'react'
import { Image, ListGroup } from 'react-bootstrap'
import { ICONS, IMAGES } from '../../../assets/images'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from 'components/UI/Button'
import { useWindowSize } from 'hooks/useWindowSize'
import { useSelector } from 'store'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const adminSidebarItems = [
  {
    title: "Map Overview",
    icon: ICONS.mapMarkerAltIcon,
    path: "/admin/map-overview"
  },
  {
    title: "Register Equipment",
    icon: ICONS.mapMarkerPlustIcon,
    path: "/admin/register-equipment"
  },
  {
    title: "Equipment Information",
    icon: ICONS.camera,
    path: "/admin/equipment-information"
  },
  {
    title: "Insect Counting",
    icon: ICONS.gallery,
    path: "/admin/insect-counting"
  },
  {
    title: "Latest News",
    icon: ICONS.news,
    path: "/admin/latest-news"
  },
  {
    title: "Live Chat",
    icon: ICONS.message,
    path: "/admin/live-chat"
  },
  {
    title: "Farmer Accounts",
    icon: ICONS.setting,
    path: "/admin/farmer-accounts"
  },
  {
    title: "Settings",
    icon: ICONS.setting,
    path: "/admin/settings"
  },
]
const SideBar = ({ onHandleToggle }) => {
  const { t } = useTranslation();

  const { userInfo } = useSelector(
    (state) => state.auth
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPathName, setCurrentPathName] = useState();

  useEffect(() => {
    setCurrentPathName(location.pathname);
  }, [location.pathname])
  
  const { width } = useWindowSize();
  return (
    <div id="sidebar-menu">
      <Button variant="none" className="d-block d-md-none text-black position-absolute ms-1" onClick={onHandleToggle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
        </svg>
      </Button>
      <div className="sidebar-header">
        <div style={{ height: 92, width: 92 }}>
          <Image 
            fluid 
            src={userInfo?.profilePicture || IMAGES.genericAvatar} 
            alt="genericAvatar" 
            className="rounded-circle" 
            style={{ 
              height: "100%",
              width: "100%",
              objectFit: "cover"
            }}
            // width={164} 
          />
        </div>
        <div className="d-flex flex-column">
          <span className="username">{userInfo?.fullname || ""}</span>
          <span className="date-now" >
            {t(moment().format('dddd'))} {" "}
            {moment().format('D')} {" "}
            {t(moment().format('MMMM'))} {" "}
            {moment().format('YYYY')}
          </span>
        </div>
      </div>
      <div 
        className="py-3 ps-5 pe-2" 
      >
        <hr className="text-dark" />
      </div>
     
      <StyledListGroup>
        {
          adminSidebarItems.map((item, index) => {
            return (
                <StyledListGroupItem 
                  className={`${currentPathName === item.path ? "active" : "" }`} 
                  onClick={() => {
                    navigate(item.path);
                    setCurrentPathName(item.path);
                    if (width <= 768) {
                      onHandleToggle();
                    }
                  }} 
                  key={index}
                >
                  <Image 
                    src={item.icon} 
                    alt={item.title} 
                  />
                  <span>{t(item.title)}</span>
                </StyledListGroupItem>
            )
          })
        }
      </StyledListGroup>
      
    </div>
  )
}
export default SideBar

const StyledListGroup = styled(ListGroup)`
  display: flex;
  flex-direction: column;
  gap: 25px;
  
`

const StyledListGroupItem = styled(ListGroup.Item)`
  text-decoration: none;
  border:none;
  gap: 20px;
  color: #464A1F;
  background: linear-gradient(to right, transparent 50%, #BEC6AB 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
  cursor: pointer;
  font-size: 16px;
  
  &:first-child {
    border-radius: 0px 100px 100px 0px;
  }

  &:last-child {
    border-radius: 0px 100px 100px 0px;
  }

  &.active {
    background-position: left bottom;
    color: #464A1F;
    background-color: #A2AA8C;
    border-radius: 0px 100px 100px 0px;
  }
`