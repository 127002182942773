import Loading from "components/UI/Loading"
import { Suspense } from "react"

// eslint-disable-next-line react/display-name
const Loadable = Component => props =>
  (
    <Suspense fallback={
      <Loading isLoading={true}/>
    }>
      <Component {...props} />
    </Suspense>
  )

  export default Loadable