import { IMAGES } from 'assets/images';
import moment from 'moment';
import React, { Fragment } from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import { useSelector } from 'store';

const Message = ({ message }) => {
  const { auth } = useSelector(
    (state) => state
  );

  return (
    <Row className="mx-0" key={message.id}>
      <Col lg="12">
        <Row className="d-flex align-items-center" >
          <Col 
            lg="auto" 
            className={`${message.senderId === auth?.userInfo?.id ? "order-first" : "order-last"}`} 
          >
            <div style={{ height: 46, width: 46 }}>
              <Image 
                fluid 
                src={message.senderId === auth?.userInfo?.id ? auth?.userInfo.profilePicture || IMAGES.genericAvatar : message.sender?.profilePicture || IMAGES.genericAvatar} 
                alt="genericAvatar" 
                className="rounded-circle" 
                style={{ 
                  height: "100%",
                  width: "100%",
                  objectFit: "cover"
                }}
                // width={164} 
              />
            </div>
            {/* <Image 
              alt="genericAvatar"
              className="rounded-circle"
              width={46}
            /> */}
          </Col>
          <Col 
            lg="10"
            className={`${message.senderId === auth?.userInfo?.id ? "p-0" : "p-3"}`}
            style={{ ...message.senderId !== auth?.userInfo?.id  && { background: "#939A80", borderRadius: "9px", fontSize: "18px" } }}
          >
            <div
              className="d-flex flex-column align-items-start gap-2 overflow-x-auto"
            >
              <span style={{ color: message.senderId === auth?.userInfo?.id ? "#464A1" : "#000", fontSize: 15 }}>{message.message}</span>
              {
                message.attachments?.length > 0 &&
                message.attachments?.map((attachment, index) => {
                  const isIncludeFileName = attachment && (attachment.filename?.split(".")[3] || attachment?.filename?.split(".")[1] || attachment.path?.split(".")[1]);
                  
                  let content;

                  if (["wav", "aac"].includes(isIncludeFileName)) {
                    content = 
                    <div>
                      <audio src={attachment.url} controls/>
                    </div>
                  }
                  if (["mp4"].includes(isIncludeFileName)) {
                    content = <div style={{
                      height: "252.51px",
                      width: "100%", borderRadius: "9px"
                    }}>
                      <video 
                        height="100%" 
                        width="100%" 
                        className="rounded" 
                        style={{ borderRadius: "9px" }}
                        controls
                      >
                        <source src={attachment?.url} type="video/mp4" />
                      </video>
                    </div>
                  }
                  if (["pdf", "docx", "xlsx"].includes(isIncludeFileName)) {
                    content = <div>
                      <a href={attachment?.url} target="_blank" rel="noopener noreferrer">
                        {attachment?.filename || attachment?.path}
                        {/* {truncateLongWord(attachment?.filename || attachment?.path, 20, 50)} */}
                      </a>
                    </div>
                  }
                  if (["jpeg", "jpg", "png"].includes(isIncludeFileName)) {
                    content = <div style={{
                      height: "252.51px",
                      width: "100%"
                    }}>
                      <Image src={attachment?.url} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "9px" }}/>
                    </div>
                  }
                  return <Fragment key={index}>
                    {content}
                  </Fragment>
                })
              }
            </div>
            <div style={{ color: "#464A1F", fontSize: 15, marginTop: 10 }}>
              <span>{moment(message.createdAt).format('H:mm')}</span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Message