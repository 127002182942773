import { createSlice } from "@reduxjs/toolkit";
import {
  addRegisterEquipments,
  getDevicesByEquipmentId,
  getRegisterEquipments,
  sendInformtionToTheApp,
} from "./actions";

function transformCoordinates (item) { 
  return {
    ...item,
    shortName: item.shortName,
    latitude: parseFloat(item.coordinates._latitude), 
    longitude: parseFloat(item.coordinates._longitude) 
  }
}

const initialState = {

  equipments: {
    list: [],
    selectedEquipment: null,
    selectedCoordinates: null,
    coordinates: [],
    selectedDevice: null,
    isSuccessAddEquipment: false,
    isLoading: false,
    error: null,
    success: null,
    message: null,
    isErrorAddEquipment: false,
    isSuccessSendInformationToTheApp: false,
    isErrorSendInformationToTheApp: false,
  },

  devices: {
    list: [],
    isLoading: false,
    error: null,
    success: null,
  },

};

const equipmentsSlice = createSlice({
  name: "equipments",
  initialState,
  reducers: {
    setModalClose: (state, action) => {
      state.equipments.isErrorAddEquipment = false;
      state.equipments.isSuccessAddEquipment = false;
      state.equipments.isSuccessSendInformationToTheApp = false
      state.equipments.isErrorSendInformationToTheApp = false
      state.equipments.message = "";
    },
    setSelectedDevice: (state, action) => {
      state.equipments.selectedDevice = action.payload;
    },
    setSelectedCoordinates: (state, action) => {
      state.equipments.selectedCoordinates = action.payload ?{
        lat: parseFloat(action.payload._latitude), 
        lng: parseFloat(action.payload._longitude) 
      } : null;
    },
    setCoordinates: (state, action) => {
      state.equipments.coordinates = action.payload ? [action.payload].map(transformCoordinates) : action.payload;
    },
    setSelectedEquipment: (state, action) => {
      state.equipments.selectedEquipment = action.payload;
    },
    resetDeviceList: (state, action) => {
      state.devices.list = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRegisterEquipments.pending, (state) => {
        state.equipments.isLoading = true;
      })
      .addCase(getRegisterEquipments.fulfilled, (state, action) => {
        state.equipments.isLoading = false;
        state.equipments.success = true;
        state.equipments.list = action.payload.d
        state.equipments.coordinates = action.payload.d.map(transformCoordinates)
      })
      .addCase(getRegisterEquipments.rejected, (state, action) => {
        state.equipments.isLoading = false;
        state.equipments.error = action.payload.m || "An error occurred";
      })

      .addCase(getDevicesByEquipmentId.pending, (state) => {
        state.devices.isLoading = true;
      })
      .addCase(getDevicesByEquipmentId.fulfilled, (state, action) => {
        state.devices.isLoading = false;
        state.devices.success = true;
        state.devices.list = action.payload.d
      })
      .addCase(getDevicesByEquipmentId.rejected, (state, action) => {
        state.devices.isLoading = false;
        state.devices.error = action.payload.m || "An error occurred";
      })

      .addCase(addRegisterEquipments.pending, (state) => {
        state.equipments.isLoading = true;
      })
      .addCase(addRegisterEquipments.fulfilled, (state, action) => {
        state.equipments.isLoading = false;
        state.equipments.isSuccessAddEquipment = true;
      })
      .addCase(addRegisterEquipments.rejected, (state, action) => {
        state.equipments.isLoading = false;
        state.equipments.isErrorAddEquipment = true;
        state.equipments.message = action.payload.m || "An error occurred";
        return state
      })

      .addCase(sendInformtionToTheApp.pending, (state) => {
        state.equipments.isLoading = true;
      })
      .addCase(sendInformtionToTheApp.fulfilled, (state, action) => {
        state.equipments.isLoading = false;
        state.equipments.isSuccessSendInformationToTheApp = true;
      })
      .addCase(sendInformtionToTheApp.rejected, (state, action) => {
        state.equipments.isLoading = false;
        state.equipments.isErrorSendInformationToTheApp = false;
        state.equipments.message = action.payload.m || "An error occurred";
        return state
      })
  },
});


export const { 
  setModalClose,
  clearNewsState,
  setSelectedDevice,
  setSelectedEquipment,
  setCoordinates,
  resetDeviceList,
  setSelectedCoordinates
} = equipmentsSlice.actions;

export default equipmentsSlice.reducer;
