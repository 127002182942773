import { combineReducers } from "@reduxjs/toolkit";

import authReducer from 'store/auth/reducer';
import userReducer from 'store/user/reducer';
import newsReducer from 'store/news/reducer';
import chatsReducer from 'store/chats/reducer';
import equipmentsReducer from 'store/equipments/reducer';
import notificationsReducer from 'store/notifications/reducer';

const rootReducer  = combineReducers({
  // Add your individual reducers here
  auth: authReducer,
  user: userReducer,
  news: newsReducer,
  chats: chatsReducer, 
  equipments: equipmentsReducer,
  notifications: notificationsReducer,
});


export default rootReducer;
