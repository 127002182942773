import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/apiService";

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/users/notifications`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error fetching notifications");
    }
  }
);
