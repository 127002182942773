const languages = {
  ms: {
    label: "Malaysia",
    // flag: russia,
  },
  en: {
    label: "English",
    // flag: usFlag,
  },
}

export default languages