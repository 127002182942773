import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/apiService";

export const getChatThreadsByAdmin = createAsyncThunk(
  "chats/getChatThreadsByAdmin",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/chats/threads`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error fetching chat threads");
    }
  }
);

export const getChatMessagesByChatThreadId = createAsyncThunk(
  "chats/getChatMessagesByChatThreadId",
  async ({ chatThreadId }, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/chats/messages/${chatThreadId}?resetNotification=1`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error fetching messages");
    }
  }
);


export const sendChatMessage = createAsyncThunk(
  "chats/sendChatMessage",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.post(`/chats/send-message`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error send message");
    }
  }
);