import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/apiService";


export const getRegisterEquipments = createAsyncThunk(
  "equipments/getRegisterEquipments",
  async (query, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/admins/devices${query ?? ""}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error fetching chat threads");
    }
  }
);

export const getDevicesByEquipmentId = createAsyncThunk(
  "equipments/getDevicesByEquipmentId",
  async ({ deviceId, query }, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.get(`/admins/equipments/${deviceId}?resetNotification=1${query ?? ""}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error fetching chat threads");
    }
  }
);

export const addRegisterEquipments = createAsyncThunk(
  "equipments/addRegisterEquipments",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.post(`/admins/devices`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error adding equipments");
    }
  }
);

export const sendInformtionToTheApp = createAsyncThunk(
  "equipments/sendInformtionToTheApp",
  async ({equipmentId, payload}, { getState, rejectWithValue }) => {
    try {
      const response = await apiService.put(`/admins/equipments/${equipmentId}`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error adding equipments");
    }
  }
);