import { ICONS } from 'assets/images'
import Button from 'components/UI/Button'
import InputField from 'components/UI/Forms/InputField'
import { COLORS, RADIUS } from 'constant/themes'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Card, CardBody, CardFooter, Col, Image, Row } from 'react-bootstrap'
import { useDispatch } from 'store'
import { publishNewsByAdmin } from 'store/news/actions'
import ReactDropZone from "react-dropzone";
import ErrorModal from 'components/UI/ErrorModal'
import SuccessModal from 'components/UI/SuccessModal'
import { useSelector } from 'store'
import { clearNewsState } from 'store/news/reducer'
import Loading from 'components/UI/Loading'
import * as Yup from "yup"
import { useTranslation } from 'react-i18next'

const initialValuesFormState = {
  title: "",
  description: "",
  images: [
    {
      file: "",
    },
    {
      file: "",
    },
    {
      file: "",
    },
    {
      file: "",
    },
  ]
}

const LatestNewsPage = () => {
  const { t } = useTranslation();

    const { isLoading, success, error } = useSelector(
    (state) => state.news
  );

  const dispatch = useDispatch();

  const [
    validationSchema,
    // setValidationSchema
  ] = useState(
    Yup.object().shape({
      title: Yup.string().required(`${t("Please Input Name")}`).test('word-count', 'Must not exceed 105 words', value => {
        if (!value) return true; // Empty values are handled by required()
        
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount <= 105;
      }),
      description: Yup.string().required(`${t("Please Input Description")}`).test('word-count', 'Must not exceed 266 words', value => {
        if (!value) return true; // Empty values are handled by required()
        
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount <= 266;
      }),
    })
  );


  // const getNews = () => {
  //   dispatch(getNewsByAdmin())
  // }

  // useEffect(() => {
  //   getNews()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const onHandleAddImages = (props) => {
    if (props.values.images.length >= 8) return
    props.setFieldValue("images", [...props.values.images, { name: "" }])
  }

  const onHandleUploadImage = (files, props, index, name) => {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        // formattedSize: formatBytes(file.size),
      })

      return file
    })

    const validFiles = files.filter(file => {
      const fileType = file.type.split('/')[1]; /* Extract file type from MIME type*/ 
      return ["jpeg", "jpg", "png"].includes(fileType);
    })
    console.log(validFiles)
    props.setFieldValue(name, props.values.images.map((image, key) =>  {
      if (key === index) {
        image.file = validFiles[0]
      }

      return image
    }));
  };

  const onHandlePublishNews = async (values, props) => {
    console.log(values, props)
    const formData = new FormData();

    // Filter images with valid files and append to formData
    values.images?.forEach(image => {
      if (image.file instanceof File) {
        formData.append("images", image.file);
      }
    });
  
    // Create payload and append to formData
    const { title, description } = values;
    formData.append("title", title);
    formData.append("description", description);
    // Dispatch the action
    await dispatch(publishNewsByAdmin(formData)).unwrap().then(res => props.resetForm());
  }

  return (
    <Row className="g-5">
      <SuccessModal 
        onOk={() => { dispatch(clearNewsState()) }} 
        onCancel={() => { dispatch(clearNewsState()) }} 
        isShow={success} 
        title={`${t("Success")}!`}
        description={t("Successfully Added News")} 
      />
      <ErrorModal 
        isShow={error} 
        title="Error!" 
        description={error} 
        onOk={() => {
          dispatch(clearNewsState())
        }}
      />
      <Loading isLoading={isLoading}/>
      <Col lg="12">
        <Formik
          validationSchema={validationSchema}
          onSubmit={onHandlePublishNews}
          initialValues={initialValuesFormState}
        >
          {props => { 
            return (
              <Form>
                <Row className="g-5">
                  <Col xxl="6" xl="12" >
                    <Row className="p-3" style={{ background: COLORS.tertiary, borderRadius: RADIUS.medium }}>
                      <h3 className="border-dark border-bottom pb-1">{t("Add Images")}</h3>
                      <Row>
                        <Col lg="12" className="mt-3 overflow-y-auto overflow-x-hidden" style={{ height: 500 }}>
                          <Row className="g-3">
                            {
                              props.values.images.map((image, index) => {
                                return (
                                  <Col lg="6" key={index}>
                                    <div 
                                      className="d-flex justify-content-center" 
                                      style={{ 
                                        height: 215,
                                        ...(image.file ? 
                                          {
                                            background: `url(${image.file.preview}) center center/cover`,
                                            backgroundSize: "100% 100%",
                                          } 
                                          : 
                                          {
                                            backgroundColor: "#fff"
                                          }
                                        )
                                      }}
                                    >
                                      <ReactDropZone
                                        onDrop={(values) => onHandleUploadImage(values, props, index, "images")}
                                      >
                                        {({ getRootProps, getInputProps }) => {
                                          return (
                                            <div
                                              className=" d-flex align-items-center gap-3 position-relative"
                                              style={{ cursor: "pointer" }}
                                              {...getRootProps()}
                                            >
                                              <div style={{ height: 164, width: 164 }}>
                                                {/* <Image 
                                                  fluid 
                                                  src={props.values.profilePicture[0]?.preview || IMAGES.genericAvatar} 
                                                  alt="genericAvatar" 
                                                  className="rounded-circle" 
                                                  style={{ 
                                                    height: "100%",
                                                    width: "100%",
                                                  }}
                                                  // width={164} 
                                                /> */}
                                              </div>
                                              <Button variant="transparent" className="p-0 position-absolute" style={{ inset: 0 }}>
                                                <Image 
                                                  variant="none"
                                                  src={ICONS.plusCircle}
                                                />
                                              </Button>
                                              <input {...getInputProps()} />
                                            </div>
                                          )
                                        }}
                                      </ReactDropZone>
                                     
                                    </div>
                                  </Col>
                                )
                              })
                            }
                          </Row>
                        </Col>
                        <Col lg="12 mt-3">
                          <Button 
                            className="w-75" 
                            variant={COLORS.dark}
                            onClick={() => {
                              onHandleAddImages(props)
                            }}
                            disabled={props.values.images.length >= 8}
                            iconLeft={t("Add a Picture (No more than 8 pictures)")}
                            iconRight={<Image src={ICONS.plusCircleWhite}/>}
                          />
                        </Col>
                      </Row>
                    </Row>
                  </Col>

                  <Col xxl="6" xl="12">
                    <Row className="p-3" style={{ background: COLORS.tertiary, borderRadius: RADIUS.medium }}>
                      <Col lg="12" className="mb-3">
                        <Card className="border-0">
                          <CardBody className="p-4">
                            <InputField 
                              controlId="formTitle"
                              type="text"
                              required={true}
                              placeholder={t("Enter Title")}
                              name="title"
                              style={{ borderBottom: "none", borderRadius: 10 }}
                              {...props} 
                            />
                          </CardBody>
                          <CardFooter className="pb-4 border-0 bg-white text-end" style={{ color: "#A2A2A2" }}>
                            {t("(Not Exceeding 105 Words)")}
                          </CardFooter>
                        </Card>
                      </Col>
                      <Col lg="12">
                        <Card className="border-0" style={{ height: "47vh" }}>
                          <CardBody className="p-4">
                            <InputField 
                              controlId="formDescription"
                              type="text"
                              required={true}
                              placeholder={t("Enter Description")}
                              name="description"
                              // maxLength={266}
                              style={{ borderBottom: "none", borderRadius: 10 }}
                              {...props} 
                            />
                          </CardBody>
                          <CardFooter className="pb-4 border-0 bg-white text-end" style={{ color: "#A2A2A2" }}>
                            {t("(Not Exceeding 266 Words)")}
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg="12">
                    <div className="d-flex justify-content-center">
                      <Button 
                        className="w-25 py-3" 
                        variant="#B88500"
                        type="submit"
                      >
                        {t("Publish News")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              
              </Form>
            )
          }}
        </Formik>
      </Col>
      
    </Row>

  )
}

export default LatestNewsPage