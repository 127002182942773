import { useField } from 'formik';
import React from 'react'
import { Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import styled from 'styled-components';

const InputField = ({
  label,
  required,
  type = 'text',
  placeholder,
  withLabel,
  isShowClear = false,
  controlId = "",
  className,
  style,
  maxLength,
  capture,
  ...props
}) => {
  const [field, meta] = useField(props.name);

  return (
    <StyledFormGroup className="mb-3" controlId={controlId}>
      {
        label && <FormLabel>{label} {required && <span className='text-danger'>*</span>} </FormLabel>
      }
      <StyledInput 
        type={type}
        placeholder={placeholder}
        {...field}
        // {...props}
        maxLength={maxLength}
        isValid={false}
        isInvalid={props.errors[props.name] && meta.touched}
        className={`rounded-0 ${className}`}
        style={style}
      />
      {
        meta.touched &&meta.error &&
        <Form.Control.Feedback type="invalid" style={{ padding: "0 0.75rem" }}>
          {meta.error}
        </Form.Control.Feedback>
      }
    </StyledFormGroup>
  )
}

export default InputField;

const StyledFormGroup = styled(FormGroup)`
`

const StyledInput = styled(FormControl)`
  background: none;
  box-shadow: none;
  border-top:none;
  border-left:none;
  border-right:none;
  border-bottom: 1px solid #C0BBB4;
  outline: none !important;

  &:focus {
    background: transparent;
    border-top:none;
    border-left:none;
    border-right:none;
    border-bottom: 1px solid #C0BBB4;
    outline: none;
    box-shadow: none;
  }

  &.is-invalid {
    box-shadow: none !important;
  }

  &::placeholder {
    color: #A2A2A2;
  }
`