import { createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "helpers/apiService";

export const registerFarmerAccountUsingEmail = createAsyncThunk(
  "user/registerFarmerAccountUsingEmail",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await apiService.post(`/signup`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message ?? "Error register");
    }
  }
);