import { useField } from 'formik';
import React from 'react'
import { Form, FormGroup, FormLabel, FormSelect } from 'react-bootstrap';
import styled from 'styled-components';

const Select = ({
  label,
  required,
  type = 'text',
  placeholder,
  withLabel,
  isShowClear = false,
  controlId = "",
  className,
  style,
  options,
  ...props
}) => {
  const [field, meta] = useField(props.name);
  return (
    <StyledFormGroup className="mb-3" controlId={controlId}>
      {
        label && <FormLabel>{label} {required && <span className='text-danger'>*</span>} </FormLabel>
      }
      <StyledSelect 
        type={type}
        placeholder={placeholder}
        {...field}
        // {...props}
        isValid={false}
        isInvalid={props.errors[props.name]}
        className={className}
        style={style}
      >
        {placeholder ? (
					<option value="" className="text-muted">
						{placeholder}
					</option>
				) : (
					''
				)}
				{options.map((item, index) => {
					return (
						<option key={index} value={item.value === field.value ? field.value : item.value} className="text-dark">
							{item.label}
						</option>
					);
				})}
      </StyledSelect>
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
    </StyledFormGroup>
  )
}

export default Select;

const StyledFormGroup = styled(FormGroup)`
  position: relative;

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    padding-right: 30px;
  }

  &::after {
    content: '▼';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #000; /* Change the color to your desired icon color */
  }
`

const StyledSelect = styled(FormSelect)`

  background: none;
  box-shadow: none;
  border-radius: none !important;
  border-top:none;
  border-left:none;
  border-right:none;
  border-bottom: 1px solid #C0BBB4;
  outline: none !important;

  &:focus {
    background: transparent;
    border-top:none;
    border-left:none;
    border-right:none;
    border-bottom: 1px solid #C0BBB4;
    outline: none;
    box-shadow: none;
  }

  &.is-invalid {
    box-shadow: none !important;
  }

  &::placeholder {
    color: #A2A2A2;
  }
`