import english from "assets/lang/english";
import indonesian from "assets/lang/indonesian";

const translations = {
  english: english,
  indonesian: indonesian
};

const getTranslations = (language) => {
  return translations[language] || translations.indonesian;
};

export default getTranslations