import Button from 'components/UI/Button'
import { COLORS } from 'constant/themes'
import React from 'react'
import { Col, Modal, ModalBody, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ContactSupportModal = ({ isShow, onCancel, onOk }) => {
  const { t } = useTranslation();

  // const handleEmailClick = () => {
  //   const email = 'support@example.com'; // Replace with your support email address
  //   const subject = 'Support Request'; // Subject of the email
  //   const body = 'Hello, I need help with...'; // Body of the email

  //   // Construct the mailto link
  //   const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  //   // Open the default email client
  //   window.open(mailtoLink);
  // };

  const copyToClipboard = (text) => {
    // Attempt to use the modern Clipboard API
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Copied to clipboard!');
      })
      .catch(err => {
        // Fall back to older method for unsupported browsers
        console.error('Failed to copy: ', err);
        fallbackCopyTextToClipboard(text);
      });
  };

  const fallbackCopyTextToClipboard = (text) => {
    // Create a temporary textarea element
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      // Execute the copy command
      const successful = document.execCommand('copy');
      const message = successful ? 'Copied to clipboard!' : 'Failed to copy';
      alert(message);
    } catch (err) {
      console.error('Unable to copy: ', err);
    }

    // Clean up
    document.body.removeChild(textArea);
  };

  const handleCopy = (text) => {
    const textToCopy = text;
    copyToClipboard(textToCopy);
  };

  return (
    <StyledModal show={isShow} onHide={onCancel} centered={true} size="lg" >
      <ModalBody>
        <Row className="">
          <Col lg="6">
            <Button
              variant="#464A1F"
              className="w-100"
              onClick={() => handleCopy("+60 12-645 6100")}
            >
              {t("Phone")} (+60 12-645 6100)
            </Button>
          </Col>
          <Col lg="6">
            <Button
              variant="#464A1F"
              className="w-100"
              onClick={() => handleCopy("vishnu@agrocloud.com.my")}
            >
              {t("Email")} (vishnu@agrocloud.com.my)
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <Modal.Footer>
        <Button variant="dark" onClick={onCancel}>
          {t("Cancel")}
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}

export default ContactSupportModal

const StyledModal = styled(Modal)`
  .modal-header {
    background-color: ${COLORS.secondary};
  }

  .modal-body {
    padding-bottom: 0px;
  }

  .modal-content {
    background-color: ${COLORS.secondary};
  }
`