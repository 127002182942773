import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Layout/Header'
import SideBar from '../Layout/SideBar'
import { Col, Container, Row } from 'react-bootstrap'
import { useResponsiveToggle } from 'hooks/useResponsiveToggle'
import { useDispatch } from 'store'
import { getUserMe } from 'store/auth/actions'

const ProtectedLayout = () => {
  const [toggle, setToggle] = useResponsiveToggle(768);

  const onHandleToggle = () => {
    setToggle(prevToggle => !prevToggle);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserMe());
  }, [dispatch])
  
  return (
    <Container fluid className="ps-0 position" style={{ background: "#EAE3D7"}}>
      <Row>
        {
          toggle && 
          <Col xxl="3" xl="4" lg="5" md="5" sm="12" className="position-fixed" style={{ zIndex: 3 }}>
            <SideBar onHandleToggle={onHandleToggle}/>
          </Col>
        }
        { toggle && <Col xxl="3" xl="4" lg="5" md="5" sm="12"></Col> }
        <Col xxl="9" xl="8" lg="7" md="7" sm="12" className="vh-100" style={{ overflowY: "auto" }}>
          <Header onHandleToggle={onHandleToggle}/>
          <Container 
            fluid 
            className="my-4" 
          >
            <Outlet/>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default ProtectedLayout