import { ICONS, IMAGES } from 'assets/images';
import Button from 'components/UI/Button';
import InputField from 'components/UI/Forms/InputField';
import Loading from 'components/UI/Loading';
import { Form, Formik } from 'formik';
import { useSignOut } from 'hooks/useSignout';
import { Fragment, useEffect, useState } from 'react';
import { Col, Image, Modal, ModalBody, Row, Stack } from 'react-bootstrap';
import { useDispatch } from 'store';
import { useSelector } from 'store';
import { updateUserProfile } from 'store/auth/actions';
import ReactDropZone from "react-dropzone";
import { get, map } from 'lodash';
import languages from 'helpers/languanges';
import { setCookie } from 'helpers/cookies';
import i18n from "../../../i18n";
import styled from 'styled-components';
import { COLORS } from 'constant/themes';
import { useTranslation } from 'react-i18next';
import LogoutConfirmationModal from './LogoutConfirmationModal';
import ContactSupportModal from './ContactSupportModal';

const SettingsPage = () => {
  const { t } = useTranslation();
  const signout = useSignOut();
  
  const [initialValues, setInitialValues] = useState({
    fullname: "",
    phoneNumber: "",
    position: "",
    profilePicture: [],
  })
  const { userInfo, isLoading } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const [isShowSelectLanguangeModalForm, setIsShowSelectLanguangeModalForm] = useState(false);
  const [isShowConfirmationLogoutModal, setIsShowConfirmationLogoutModal] = useState(false);
  const [isShowContactSupportModal, setIsShowContactSupportModal] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setInitialValues(prevState => {
        return {
          ...prevState,
          fullname: userInfo.fullname  || "",
          phoneNumber: userInfo.phoneNumber  || "",
          position: userInfo.position || "",
          profilePicture: userInfo.profilePicture ? [
            {
              preview: userInfo.profilePicture,
            }
          ] : [],
        }
      })
    }
  }, [userInfo])

  const onHandleUpdateProfile = (values) => {

    const formData = new FormData() 

    if (values.profilePicture?.length > 0) {
      values.profilePicture.map(file => {
        if (file instanceof File) {
          formData.append("profilePicture", file)
        }
        return file
      })
    }

    for (const key in values) {
      formData.append(key, values[key]);
    }

    dispatch(updateUserProfile(formData));
  }
  
  const onHandleProfileUpload = (files, props, name) => {
    files.map(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        // formattedSize: formatBytes(file.size),
      })

      return file
    })

    const validFiles = files.filter(file => {
      const fileType = file.type.split('/')[1]; /* Extract file type from MIME type*/ 
      return ["jpeg", "jpg", "png"].includes(fileType);
    })
    props.setFieldValue(name, validFiles);
  };

  const changeLanguageAction = lang => {
    i18n.changeLanguage(lang);
    setCookie("I18N_LANGUAGE", lang);
    setIsShowSelectLanguangeModalForm(false);
  }

  const onHandleShowChangeLanguangeModal = () => {
    setIsShowSelectLanguangeModalForm(true);
  }

  return (
    <Row className="g-5">
      <LogoutConfirmationModal isShow={isShowConfirmationLogoutModal} onOk={signout} onCancel={() => setIsShowConfirmationLogoutModal(false)}/>
      <ContactSupportModal isShow={isShowContactSupportModal} onOk={signout} onCancel={() => setIsShowContactSupportModal(false)}/>
      <StyledModal show={isShowSelectLanguangeModalForm} onHide={() => setIsShowSelectLanguangeModalForm(false)} centered={true} >
        <ModalBody>
          <Row className="d-flex justify-content-center">
            {
              map(Object.keys(languages), key => (
                <Fragment key={key}>
                  <Col lg="3">
                    <Button
                      variant="#464A1F"
                      onClick={() => changeLanguageAction(key)}
                    >
                      {get(languages, `${key}.label`)}
                    </Button>
                  </Col>
                </Fragment>
              ))
            }
          </Row>
        </ModalBody>
      </StyledModal>
      <Col xxl="6" xl="12" >
        <Row className="px-4 py-4" style={{ background: "#DAD7C8", borderRadius: "20px", height: 720, position: "relative" }}>
          <h3>{t("Update Profile")}</h3>
          <hr/>
          <Loading isLoading={isLoading} text="Loading..."/>
          <Formik
            enableReinitialize={true}
            // validationSchema={validationSchema}
            onSubmit={onHandleUpdateProfile}
            initialValues={initialValues}
          >
            {props => {
              return (
                <Form className="h-100 d-flex flex-column align-items-center justify-content-center">
                  <div className=" d-flex align-items-center justify-content-center my-5">
                    <ReactDropZone
                      onDrop={(values) => onHandleProfileUpload(values, props, "profilePicture")}
                    >
                      {({ getRootProps, getInputProps }) => {
                        return (
                          <div
                            className=" d-flex align-items-center gap-3 position-relative"
                            style={{ cursor: "pointer" }}
                            {...getRootProps()}
                          >
                            <div style={{ height: 164, width: 164 }}>
                              <Image 
                                fluid 
                                src={props.values.profilePicture[0]?.preview || IMAGES.genericAvatar} 
                                alt="genericAvatar" 
                                className="rounded-circle" 
                                style={{ 
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover"
                                }}
                                // width={164} 
                              />
                            </div>
                            <input {...getInputProps()} />
                            <Button 
                              className="rounded-circle" 
                              variant="#464A1F"
                              style={{ 
                                width: 69, 
                                height: 69, 
                                background: "#464A1F", 
                                position: "absolute", 
                                top: "calc(100% - 70px)", 
                                marginLeft: "120px" 
                              }}
                            >
                              <Image src={ICONS.pen}/>
                            </Button>
                            {/* asd */}
                            {/* <CameraPlainIcon/> Change Cover */}
                          </div>
                        )
                      }}
                    </ReactDropZone>
                    {/* <Image fluid src={userInfo?.profilePicture || IMAGES.genericAvatar} alt="genericAvatar" className="rounded-circle" width={164} /> */}
                    {/* <Button 
                      className="rounded-circle" 
                      variant="#464A1F"
                      style={{ width: 69, height: 69, background: "#464A1F", position: "absolute", top: "calc(100% - 70px)", marginLeft: "150px" }}
                    >
                      <Image src={ICONS.pen}/>
                    </Button> */}
                  </div>
                  <Stack gap={3}>
                    <InputField 
                      controlId="formName"
                      type="text"
                      required={true}
                      placeholder={t("Fullname")}
                      // placeholder={translateLanguange().usernameLabel}
                      name="fullname"
                      {...props} 
                    />
                    <InputField 
                      controlId="formPhoneNumber"
                      type="number"
                      required={true}
                      placeholder={t("Contact Number")}
                      // placeholder={translateLanguange().passwordLabel}
                      name="phoneNumber"
                      {...props} 
                    />
                    <InputField 
                      controlId="formPosition"
                      type="text"
                      required={true}
                      placeholder={t("Position")}
                      // placeholder={translateLanguange().passwordLabel}
                      name="position"
                      {...props} 
                    />
                    <div className="d-flex justify-content-center">
                      <Button className="w-25 py-3" variant="#B88500" type="submit">
                        {t("Update Profile")}
                      </Button>
                    </div>
                  </Stack>
                </Form>
              )
            }}
          </Formik>
        </Row>
      </Col>
      <Col xxl="6" xl="12" >
        <Row className="px-5 py-4" style={{ background: "#F2EDE8", borderRadius: "20px", height: 720 }}>
          <Stack className="d-flex justify-content-center align-items-center" gap={2}>
            <Button
              variant="#464A1F"
              className="rounded-pill px-5 w-75"
              style={{ height: 90 }}
              onClick={() => window.location.href = "/forgot-password-step-1"}
              iconRight={<Image src={ICONS.lock}/>}
            >
              {t("Change Password")}
            </Button>
            <Button 
              className="rounded-pill px-5 w-75"
              variant="#464A1F"
              style={{ height: 90 }}
              onClick={onHandleShowChangeLanguangeModal}
              iconRight={<Image src={ICONS.language}/>}
            >
              {t("Change Language")}
            </Button>
            <Button 
              variant="#464A1F"
              className="rounded-pill px-5 w-75"
              style={{ height: 90 }}
              iconRight={<Image src={ICONS.phone}/>}
              onClick={() => setIsShowContactSupportModal(true)}
            >
              {t("Contact Support")}
            </Button>
            <Button 
              variant="#464A1F"
              className="rounded-pill px-5 w-75"
              style={{ height: 90 }}
              onClick={() => setIsShowConfirmationLogoutModal(true)}
              iconRight={<Image src={ICONS.logout}/>}
            >
              {t("Log Out")}
            </Button>
          </Stack>
        </Row>
      </Col>
    </Row>
  );
}

export default SettingsPage;

const StyledModal = styled(Modal)`
  .modal-header {
    background-color: ${COLORS.secondary};
  }

  .modal-content {
    background-color: ${COLORS.secondary};
  }
`