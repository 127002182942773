// import { getCookie } from "helpers/cookies";
import React, {
  //  useEffect 
  } from "react";
// import { useDispatch } from "react-redux";
import { 
  Navigate, 
  // useNavigate 
} from "react-router-dom";
import { useSelector } from "store";
// import { cookiesigninUserDataExpiredState } from "store/auth/reducer";

const AuthMiddlewareRoutes = (props) => {
  const { isAuthenticated, role, isFinishedProfile } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // // CHECK IF signinUserData COOKIE EXPIRED
  // useEffect(() => {
  //   const checkCookie = () => {
  //     const signinUserData = getCookie("signinUserData");
  //     if (!signinUserData) {
  //       navigate("/signin");
  //       dispatch(cookiesigninUserDataExpiredState());
  //     }
  //   };

  //   // Check the cookie initially
  //   checkCookie();

  //   // Set up an interval to check the cookie every 30 seconds
  //   const intervalId = setInterval(checkCookie, 30000);

  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [navigate, dispatch]);

  if (isAuthenticated) {
    if (isFinishedProfile) { // CHECK IF PROFILE IF FINISHED THEN IF NOT REDIRECT TO EDIT PROFILE PAGE
      if (role === props.role) {
        // Checking current login role and redirect to authorized pages based on the current role
        return <React.Fragment>{props.children}</React.Fragment>;
      } else {
        return <Navigate to="/unauthorized" />;
      }
    } else {
      return <Navigate to="/edit-profile" />;
    }
  } else {
    return <Navigate to={{ pathname: "/signin", state: { from: props.location } }} />;
  }
};

export default AuthMiddlewareRoutes;