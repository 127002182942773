import { createSlice } from "@reduxjs/toolkit";
import {
  getNotifications,
} from "./actions";

const initialState = {
  data: null,
  list: [],
  chatCount: [],
  notifCount: [],
  isLoading: false,
  error: null,
  success: null,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clearNotificationsState: (state, action) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.list = action.payload.d
        state.notifCount = action.payload.d.filter(notif => notif.type === "equipment");
        state.chatCount = action.payload.d.filter(notif => notif.type === "chat");
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.m || "An error occurred";
      })
  },
});


export const { 
  clearNotificationsState,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
